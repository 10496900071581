 export const validate = values => {
 
  const errors = {}
  if (!values.brand || String(values.brand).trim()=='' || typeof(values.brand)=='undefined') {
    errors.brand = 'Required';
  }
  if (!values.identifier) {
    errors.identifier = 'Required';
  }
  if (!values.title) {
    errors.title = 'Required';
  } else if (values.title.length > 35) {
    errors.title = 'Must be 35 characters or less'
  }
  if (!values.url) {
    errors.url = 'Required';
  }
  if (!values.description) {
    errors.description = 'Required'
  } 
  if (!values.amount) {
    errors.amount = 'Required'
  } else if (isNaN(Number(values.amount))) {
    errors.amount = 'Must be a number'
  }
  if (!values.unit) {
    errors.unit = 'Required';
  }
  if (values.start_age && values.end_age && values.start_age > values.end_age) {
    errors.start_age = 'Must be greater than "End age'
  }

  return errors
}