import React, {Component} from 'react';


export default function reviewShow(props) {

    let {review} = props;
    return (
      <div class="container reviews-modal">
        <div class="row">
          <div class="col-12 col-sm-12">
            <h1>Review details</h1>
            <table className="table mt-4">
              <tbody>
                <tr>
                  <th className="bg-light">ID:</th><td>{review.id}</td>
                </tr>
                <tr>
                  <th className="bg-light w-50">Brand:</th><td>{review.brand!=null ? review.brand.name : '---'}</td>
                </tr>
                <tr>
                  <th className="bg-light w-50">Title:</th><td>{review.title}</td>
                </tr>
                <tr>
                  <th className="bg-light w-50">Description:</th><td>{review.description}</td>
                </tr>
                <tr>
                  <th className="bg-light w-50">Rating:</th><td>{review.rating}</td>
                </tr>
                <tr>
                  <th className="bg-light">Created at:</th><td>{review.created_at ? review.created_at.substring(0,10) : '---'}</td>
                </tr>
                <tr>
                  <th className="bg-light">Active:</th><td>{review.active ? 'Active' : 'Inactive'}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
}