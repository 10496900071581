// Action Types
import {
        GET_PAYMENT_SUCCESS, GET_PAYMENTS_SUCCESS, ADD_PAYMENT_SUCCESS
        } from '../actions/actionTypes';

// Utils
import { getNewState } from '../shared/utils/frontend';

// Initial State
const initialState = {};

export default function paymentReducer(state = initialState, action) {
  switch (action.type) {
    
    /*
    case GET_PAYMENT_SUCCESS:
    {
      const {payload: payment} = action;
      console.log('ACTION');
      console.log(action);
      return getNewState(state, {
        payment
      });
    }
    break;
    */
    
    case GET_PAYMENTS_SUCCESS:
    {
      const {payload: result} = action;
      console.log('ACTION');
      console.log(action);
      return getNewState(state, {
        result
      });
    }
    break;
    
    /*
    case ADD_PAYMENT_SUCCESS:
    {
      const {payload: payments} = action;
      return getNewState(state, {
        payments
      });
    }
    break;
    */
 
  default:
      return state;
}
};