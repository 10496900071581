import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';

const Tabs = (props) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  return (
    <div>
      <Nav tabs>
        {
          props.tabs.map((tab,index)=>(
            <NavItem key={'ni'+index}>
              <NavLink
                key={'nl'+index}
                className={classnames({ active: activeTab === String(index+1) })}
                onClick={() => { toggle(String(index+1)); }}
              >
                {tab.label}
              </NavLink>
            </NavItem>
          ))
        }
      </Nav>
      <TabContent activeTab={activeTab}>
        {
          props.tabs.map((tab,index)=>(
            <TabPane key={'tp'+index} tabId={String(index+1)}>
              {tab.items}
            </TabPane>
          ))
        }
      </TabContent>
    </div>
  );
}

export default Tabs;