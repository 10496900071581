// Action Types
import {GET_CATEGORY_SUCCESS, GET_CATEGORYS_SUCCESS, ADD_CATEGORY_SUCCESS, ADD_CATEGORY_ERROR, UPDATE_CATEGORY_ERROR} from '../actions/actionTypes';

// Utils
import { getNewState } from '../shared/utils/frontend';

// Initial State
const initialState = {
  category: []
};

export default function categoryFormReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CATEGORY_SUCCESS: {
      const {payload: category} = action;
      return getNewState(state, {category});
      break;
    }
    case ADD_CATEGORY_SUCCESS: {
      const {payload: category} = action;
      return getNewState(state, {category});
      break;
    }
    case ADD_CATEGORY_ERROR: {
      const {payload: category} = action;
      return getNewState(state, {category});
      break;  
    }    
    case UPDATE_CATEGORY_ERROR: {
      const {payload: category} = action;
      return getNewState(state, {category});
      break;
    }
    default:
      return state;
  }
}