import React, { Component } from 'react';

import { bool } from 'prop-types';
import { connect } from 'react-redux';

class Home extends Component {

  constructor(props) {
    super(props);
    const {isMobile} = props;
    this.state = {
      name: 'Jane Doe'
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        name: 'John Doe'
      })
    }, 1000);
  }

  render() {

    const isLoggedIn = this.props.logged_in;
    let button;
    if(isLoggedIn) {
      button = <button>Logout</button>;
    }
    return (
            <div className="Home jumbotron">
              <h1>I'm Benfido CLO</h1>
              <p>Hi, my name is {this.state.name}!</p>
              <p>You are using: <strong>{this.props.isMobile ? 'mobile' : 'desktop'}</strong></p>
              {/*<p><a href="https://www.linteractive.bg">External link</a></p>
              {button}*/}
            </div>
            );
  }
}

Home.propTypes = {
  isMobile: bool
};

function mapStateToProps(state) {
  return {
    isMobile: state.device.isMobile,
    logged_in: state.session
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);