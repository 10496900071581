import React, { Component } from 'react';

export default function CouponShow(props) {

  let { coupon } = props;
  return (
    <div class="container coupons-modal">
      <div class="row">
        <div class="col-12 col-sm-6">
          <table className="table mt-4">
            <tbody>
              <tr>
                <th className="bg-light w-50">Brand:</th><td>{coupon.brand.name}</td>
              </tr>
              <tr>
                <th className="bg-light w-50">Title:</th><td>{coupon.title.en}</td>
              </tr>
              <tr>
                <th className="bg-light">Identifier:</th><td>{coupon.code}</td>
              </tr>
              <tr>
                <th className="bg-light">Amount:</th><td>{coupon.amount}</td>
              </tr>
              <tr>
                <th className="bg-light">Unit:</th><td>{coupon.unit}</td>
              </tr>
              <tr>
                <th className="bg-light">Description:</th><td>{coupon.description.en ? coupon.description.en : '---'}</td>
              </tr>
              <tr>
                <th className="bg-light">Active:</th><td>{coupon.active ? 'Active' : 'Inactive'}</td>
              </tr>
              <tr>
                <th className="bg-light">Start date:</th><td>{coupon.start_date ? coupon.start_date.substring(0, 10) : '---'}</td>
              </tr>
              <tr>
                <th className="bg-light">End date:</th><td>{coupon.end_date ? coupon.end_date.substring(0, 10) : '---'}</td>
              </tr>
              <tr>
                <th className="bg-light">Online:</th><td>{coupon.online ? 'Yes' : 'No'}</td>
              </tr>
              <tr>
                <th className="bg-light">Offline:</th><td>{coupon.offline ? 'Yes' : 'No'}</td>
              </tr>
              <tr>
                <th className="bg-light">Male:</th><td>{coupon.male ? 'Yes' : 'No'}</td>
              </tr>
              <tr>
                <th className="bg-light">Female:</th><td>{coupon.female ? 'Yes' : 'No'}</td>
              </tr>
              <tr>
                <th className="bg-light">Payments count:</th><td>{coupon.payments_count}</td>
              </tr>
              <tr>
                <th className="bg-light">Turnover:</th><td>{coupon.turnover}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12 col-sm-6">
          <table className="table mt-4">
            <tbody>
              <tr>
                <th className="bg-light">Categories:</th>
                <td>
                  {
                    coupon.categories.map((category) => (
                      <div>- {category.name.en}</div>
                    ))
                  }
                </td>
              </tr>
              <tr>
                <th className="bg-light">Countries:</th>
                <td>
                  {
                    coupon.countries.map((country) => (
                      <div>- {country.name.en}</div>
                    ))
                  }
                </td>
              </tr>
              <tr>
                <th className="bg-light">Brands:</th>
                <td>
                  {
                    coupon.brands.map((brand) => (
                      <div>- {brand.name}</div>
                    ))
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}