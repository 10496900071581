import {KJUR, b64utoutf8} from 'jsrsasign';
import {setCookie, getCookie} from './cookies';


export function createAccountToken(account) {
  // Header
  var oHeader = {alg: 'HS256', typ: 'JWT'};
  // Payload
  var oPayload = {};
  var tNow = KJUR.jws.IntDate.get('now');
  var tEnd = KJUR.jws.IntDate.get('now + 1day');
  oPayload.id = account.id;
  oPayload.role = account.role;
  oPayload.locale = account.locale;
  // Sign JWT
  var sHeader = JSON.stringify(oHeader);
  var sPayload = JSON.stringify(oPayload);
  var sJWT = KJUR.jws.JWS.sign("HS256", sHeader, sPayload, process.env.REACT_APP_SECRET_CODE);
  console.log('---sJWT');
  console.log(sJWT);
  return sJWT;
}

export function checkAccountDetails() {
  console.log('sJWT isValid');
  var sJWT = getCookie('_ud');
  if(sJWT=='') {
  	return false
  }
  var isValid = KJUR.jws.JWS.verifyJWT(sJWT, process.env.REACT_APP_SECRET_CODE, {alg: ['HS256']});
  var payloadObj = KJUR.jws.JWS.readSafeJSONString(b64utoutf8(sJWT.split(".")[1]));
  console.log(isValid);
}