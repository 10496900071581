// Dependencies
import axios from 'axios';
import { API_URL, X_API_KEY } from '../conf.js';
import { getCookie } from '../shared/utils/cookies';
import { axiosInterceptors } from '../shared/utils/axiosInterceptors';

// Action Types
import {
  GET_REVIEW_REQUEST,
  GET_REVIEW_SUCCESS,
  GET_REVIEW_ERROR,
  GET_REVIEWS_REQUEST,
  GET_REVIEWS_SUCCESS,
  GET_REVIEWS_ERROR,
  ADD_REVIEW_REQUEST,
  ADD_REVIEW_SUCCESS,
  ADD_REVIEW_ERROR,
  DELETE_REVIEW_REQUEST,
  DELETE_REVIEW_SUCCESS,
  DELETE_REVIEW_ERROR,
  UPDATE_REVIEW_REQUEST,
  UPDATE_REVIEW_SUCCESS,
  UPDATE_REVIEW_ERROR
} from './actionTypes';

// Base Actions
import { request, received, error } from '../shared/redux/baseActions';

const getReview = (params) => dispatch => {
  dispatch(request(GET_REVIEW_REQUEST));
  var id = '';
  var bank_id = '';
  if (typeof (params) !== 'undefined') {
    id = params.id;
    bank_id = params.bank_id;
  }
  // Axios Data
  const axiosData = {
    method: 'GET',
    url: API_URL + '/reviews/' + id + '/?bank_id=' + bank_id,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    }
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(GET_REVIEW_SUCCESS, response.data)))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(GET_REVIEW_ERROR));
    });
};

const getReviews = (params) => dispatch => {
  dispatch(request(GET_REVIEWS_REQUEST));
  var perPage = 10;
  var page = 1;
  console.log('GET_REVIEWS');
  console.log(params);
  var url = API_URL + '/reviews?';
  var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
  console.log(queryString);
  url += queryString;
  // Axios Data
  const axiosData = {
    method: 'GET',
    url: url,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    }
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(GET_REVIEWS_SUCCESS, { items: response.data['items'], meta: response.data["meta"] })))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(GET_REVIEWS_ERROR));
    });
};

const addReview = (params) => dispatch => {
  dispatch(request(ADD_REVIEW_REQUEST));
  var id = '';
  if (typeof (params) !== 'undefined') {
    id = params;
  }
  console.log('ADD_REVIEW');
  console.log(params);
  params.address_attributes = params.address
  params.contact_attributes = params.contact
  // Axios Data
  const axiosData = {
    method: 'POST',
    url: API_URL + '/reviews',
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    },
    data: params
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(ADD_REVIEW_SUCCESS, response.data['form'])))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(ADD_REVIEW_ERROR, err.response));
    });
};

const updateReview = (params, bank_id) => dispatch => {
  dispatch(request(UPDATE_REVIEW_REQUEST));
  var id = '';
  if (typeof (params) !== 'undefined') {
    id = params;
  }
  console.log('UPDATE_REVIEW');
  console.log(params);
  params = { ...params, ...{ address_attributes: params.address } }
  params = { ...params, ...{ contact_attributes: params.contact } }
  // Axios Data
  const axiosData = {
    method: 'PATCH',
    url: API_URL + '/reviews/' + params.id + '/?bank_id=' + bank_id,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    },
    data: params
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(UPDATE_REVIEW_SUCCESS, response.data['form'])))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(UPDATE_REVIEW_ERROR, err.response));
    });
};

const deleteReview = (id) => dispatch => {
  dispatch(request(DELETE_REVIEW_REQUEST));
  // Axios Data
  const axiosData = {
    method: 'DELETE',
    url: API_URL + '/reviews/' + id,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    }
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(DELETE_REVIEW_SUCCESS, response.data)))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(DELETE_REVIEW_ERROR, err.response));
    });
};

const resetReview = () => dispatch => {
  dispatch(received(ADD_REVIEW_SUCCESS, []))
};

export { getReview, getReviews, addReview, updateReview, resetReview, deleteReview };
