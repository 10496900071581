export function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

export function getDates() {
  var nowdate = new Date();
  var monthStartDay = new Date(nowdate.getFullYear(), nowdate.getMonth(), 1);
  var monthEndDay = new Date(nowdate.getFullYear(), nowdate.getMonth() + 1, 0);
  return {startDate: monthStartDay.toLocaleDateString('en-CA'), endDate: monthEndDay.toLocaleDateString('en-CA')}
}

export function  comparer(otherArray){
  return function(current){
    return otherArray.filter(function(other){
      return other.id == current.id
    }).length == 0;
  }
}

export function  arrayComparer(otherArray){
  return function(current){
    return otherArray.filter(function(other){
      return other == current
    }).length == 0;
  }
}

export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
