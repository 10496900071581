import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import session from '../../reducers/sessionReducer';

import banks from '../../reducers/banksReducer';
import bankForm from '../../reducers/bankFormReducer';

import brands from '../../reducers/brandsReducer';
import brandForm from '../../reducers/brandFormReducer';

import categories from '../../reducers/categoriesReducer';
import categoryForm from '../../reducers/categoryFormReducer';

import countries from '../../reducers/countriesReducer';
import countryForm from '../../reducers/countryFormReducer';

import accounts from '../../reducers/accountsReducer';

import coupons from '../../reducers/couponsReducer';
import couponForm from '../../reducers/couponFormReducer';

import cashbacks from '../../reducers/cashbacksReducer';
import cashbackForm from '../../reducers/cashbackFormReducer';

import payments from '../../reducers/paymentsReducer';
import paymentForm from '../../reducers/paymentFormReducer';

import customers from '../../reducers/customersReducer';
import customerForm from '../../reducers/customerFormReducer';

import transactions from '../../reducers/transactionsReducer';
import transactionForm from '../../reducers/transactionFormReducer';

import reviews from '../../reducers/reviewsReducer';
import reviewForm from '../../reducers/reviewFormReducer';

import analytic from '../../reducers/analyticReducer';
import globalParams from '../../reducers/globalParamsReducer';

import contractTypes from '../../reducers/contractTypesReducer';
import contractTypeForm from '../../reducers/contractTypeFormReducer';

// Shared Reducers
import device from './deviceReducer';

const rootReducer = combineReducers({
  device: device,
  session: session,
  bankForm: bankForm,
  banks: banks,
  brandForm: brandForm,
  brands: brands,
  categoryForm: categoryForm,
  categories: categories,
  countryForm: countryForm,
  countries: countries,
  accounts: accounts,
  couponForm: couponForm,
  coupons: coupons,
  cashbackForm: cashbackForm,
  cashbacks: cashbacks,
  paymentForm: paymentForm,
  payments: payments,
  customerForm: customerForm,
  customers: customers,
  transactionForm: transactionForm,
  transactions: transactions,
  reviewForm: reviewForm,
  reviews: reviews,
  analytic: analytic,
  contractTypeForm: contractTypeForm,
  contractTypes: contractTypes,
  globalParams: globalParams,
  form: formReducer
});

export default rootReducer;