// Action Types
import {
        GET_BRAND_SUCCESS, GET_BRANDS_SUCCESS, ADD_BRAND_SUCCESS
        } from '../actions/actionTypes';

// Utils
import { getNewState } from '../shared/utils/frontend';

// Initial State
const initialState = {};

export default function brandReducer(state = initialState, action) {
  switch (action.type) {
    
    /*
    case GET_BRAND_SUCCESS:
    {
      const {payload: brand} = action;
      console.log('ACTION');
      console.log(action);
      return getNewState(state, {
        brand
      });
    }
    break;
    */
    
    case GET_BRANDS_SUCCESS:
    {
      const {payload: result} = action;
      console.log('ACTION');
      console.log(action);
      return getNewState(state, {
        result
      });
    }
    break;
    
    /*
    case ADD_BRAND_SUCCESS:
    {
      const {payload: brands} = action;
      return getNewState(state, {
        brands
      });
    }
    break;
    */
 
  default:
      return state;
}
};