// Action Types
import {GET_CUSTOMER_SUCCESS, GET_CUSTOMERS_SUCCESS, ADD_CUSTOMER_SUCCESS, ADD_CUSTOMER_ERROR, UPDATE_CUSTOMER_ERROR} from '../actions/actionTypes';

// Utils
import { getNewState } from '../shared/utils/frontend';

// Initial State
const initialState = {};

export default function customerFormReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CUSTOMER_SUCCESS: {
      const {payload: customer} = action;
      return getNewState(state, {customer});
      break;
    }
    case ADD_CUSTOMER_SUCCESS: {
      const {payload: customer} = action;
      return getNewState(state, {customer});
      break;
    }
    case ADD_CUSTOMER_ERROR: {
      const {payload: customer} = action;
      return getNewState(state, {customer});
      break;  
    }    
    case UPDATE_CUSTOMER_ERROR: {
      const {payload: customer} = action;
      return getNewState(state, {customer});
      break;
    }
    default:
      return state;
  }
}