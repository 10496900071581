export function axiosInterceptors(axios) {
  // Add a response interceptor
  axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // return Promise.reject(error.response);
    switch (error.response.status) {
      case 422:
        alert(`Result:\n\n${JSON.stringify(error.response.data, null, 2)}`);
        break
      case 401:
        alert('Unauthorized!');
        window.location.href = '/login';
        break;
      case 403:
        alert('Forbidden!');
        //window.location.href = '/login';
        //return error.response;
        break;  
      case 500:
        alert('We had a problem with our server. Try again later');
        //window.location.href = '/login';
        break;
      case 503:
        alert('We\'re temporarily offline for maintenance. Please try again later.');
        window.location.href = '/login';
        break;
    }
  });
}