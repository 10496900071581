// Action Types
import {GET_BRAND_SUCCESS, GET_BRANDS_SUCCESS, ADD_BRAND_SUCCESS, ADD_BRAND_ERROR, UPDATE_BRAND_ERROR} from '../actions/actionTypes';

// Utils
import { getNewState } from '../shared/utils/frontend';

// Initial State
const initialState = {};

export default function brandFormReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BRAND_SUCCESS: {
      console.log('ACTION');
      console.log(action);
      const {payload: brand} = action;
      return getNewState(state, {brand});
      break;
    }
    case ADD_BRAND_SUCCESS: {
      const {payload: brand} = action;
      return getNewState(state, {brand});
      break;
    }
    case ADD_BRAND_ERROR: {
      const {payload: brand} = action;
      return getNewState(state, {brand});
      break;  
    }    
    case UPDATE_BRAND_ERROR: {
      const {payload: brand} = action;
      return getNewState(state, {brand});
      break;
    }
    default:
      return state;
  }
}