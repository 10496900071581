import React, { Component } from 'react';
import Checkbox from '@material-ui/core/Checkbox';


const ref = React.createRef();

class MaterialCheckbox extends Component {

  render () {
    const {forwardedRef, ...rest} = this.props;
    return (
      <Checkbox  ref={forwardedRef} {...rest} color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} />
    )
  }
}

export default MaterialCheckbox;