import React, {Component} from 'react';
import debounce from 'lodash/debounce';
import { PropTypes, array, bool } from 'prop-types';
import Combobox from 'react-widgets/lib/Combobox';
import 'react-widgets/dist/css/react-widgets.css';
import { renderCombobox } from '../form-field/ReduxFormFields';
import { getCategory, getCategories } from '../../../actions/categoryActions';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Redux Store
import configureStore from '../../redux/configureStore';
// Configuring Redux Store
const store = configureStore(window.initialState);

class CategoryCombobox extends Component { 
	
	constructor(props) {
	  super(props);
	  this.state = {
      open: false,
      create: false,
      search: null,
      categories: null,
      selectedCategory: '',
      categorySearchResult: null
	    }
		this.handleCategoryChange = this.handleCategoryChange.bind(this);
	  this.handleCategorySearch = this.handleCategorySearch.bind(this);
	}

	componentDidMount(props) {
    console.log('componentDidMount');
    console.log(props);
    const { getCategories } = this.props;
    getCategories();
  }

  componentDidUpdate(prevProps) {
    console.log('componentDidUpdate')
    console.log(this.props);
  }

  handleCategoryChange(e) {
  	console.log('--searchable-category-change');
  	console.log(e);
    this.setState({selectedCategory: e=='' ? null : e});
    if(typeof(e.id)=='undefined') {
      this.handleCategorySearch(e);
    }
    else {
    	const search = Object.assign({}, this.state.search);
	    search['search[category]'] = encodeURIComponent(e.id);
	    this.setState({search});
	    this.props.onChange('category', e.id);
    }
  }

  handleCategorySearch = debounce((searchTerms) => {
    this.categorySearch(searchTerms);
  }, 500);

  categorySearch(searchTerms) {
  	console.log(searchTerms)
    console.log('handleCategorySearch -- searchable');
    this.props.onChange('category', '');
    var params = {}
    var search = {}
    search['search[category]'] = encodeURIComponent(searchTerms.trim());
    Object.assign(params, search);
    store.dispatch(getCategories(params)).then((result) => {
      var categorySearchResult = result.payload.items.map(category => ({ id: category.id, name: category.name.en }));
      this.setState({categorySearchResult: categorySearchResult});
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    }); 
  }

	render() {

		const { categories } = this.props;

		console.log('---render');

    var selectedCategory = '';
    if(this.state.selectedCategory==null) {
      selectedCategory = '';
    }
    else if(this.state.selectedCategory!='') {  
      selectedCategory = this.state.selectedCategory;
    }
		var categoriesArr = categories.map(category => ({ id: category.id, name: category.name.en }));
    if(this.state.categorySearchResult!=null) {
      categoriesArr = this.state.categorySearchResult;
    }
    categoriesArr = categoriesArr.concat({id: 0, name: '...'});

		return (
			<Combobox
			  name="category"
			  type="select"
			  label="Category - type to search Category e.g. Electronics"
			  data={categoriesArr}
			  defaultValue={selectedCategory}
			  valueField="id"
			  textField="name"
			  filter={true}
			  className={`p-0 pr-2 ${typeof(this.props.className)!='undefined' ? this.props.className : ''}`}
			  onChange={(event) => this.handleCategoryChange(event)}
			  disabled={[{id:0, name: '...'}]}
			  placeholder="All Categories"
			  asterisk="*"
			/>
		)
	}

}

function mapStateToProps(state) {
	console.log('STATE')
	console.log(state);
  return {
    categories: (typeof(state.categories)!=='undefined' && typeof(state.categories.result)!=='undefined') ? state.categories.result.items : [],
    session: state.session
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({getCategories}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryCombobox)
