import React from 'react';

import * as serviceWorker from './serviceWorker';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import { Provider } from 'react-redux';

// Routes
import AppRoutes from './routes';
import { createBrowserHistory } from "history";

// Redux Store
import configureStore from './shared/redux/configureStore';

// Configuring Redux Store
const store = configureStore(window.initialState);
const history = createBrowserHistory();

localStorage.removeItem('items');

render(
	<Provider store={store}>
		<Router history={history}>
	  	<AppRoutes />
		</Router>
	</Provider>
	,document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
