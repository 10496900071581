import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Badge, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem } from 'reactstrap';
import PropTypes from 'prop-types';
import Brightness4RoundedIcon from '@material-ui/icons/Brightness4Rounded';
import Brightness5SharpIcon from '@material-ui/icons/Brightness5Sharp';
import { AppAsideToggler, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import logo from '../../../shared/images/logo.png'
import sygnet from '../../../shared/images/logo-minimized.png'
import SimpleLineIcon from 'react-simple-line-icons';
import { ThemeContext } from '../../../shared/context/theme';
import Countdown from '../countdown/Countdown';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as sessionActions from '../../../actions/sessionActions';
import { createBrowserHistory } from "history";
const history = createBrowserHistory()

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
  
   constructor(props) {
    super();
    this.logOut = this.logOut.bind(this);
    this.refreshAccessToken = this.refreshAccessToken.bind(this);
    this.signOutTest = this.signOutTest.bind(this);
    this.switchDarkMode = this.switchDarkMode.bind(this);
    if(process.env.NODE_ENV=='development') {
      console.log('LOGGED IN - ToDo');
      //Аt the bottom of the page
      console.log(props.logged_in);
    }
  }
  
  logOut(event) {
    event.preventDefault();
    //this.props.actions.logOutUser();
    this.props.actions.signOutUser();
    window.location.replace("/login");
  }

  refreshAccessToken(event) {
    event.preventDefault();
    this.props.actions.refreshToken();
  }

  signOutTest(event) {
    event.preventDefault();
    this.props.actions.signOutUser();
  }

  switchDarkMode() {
    this.props.switchDarkMode();
  }

  // Here you can define your PropTypes.
  static propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string
  };
  
  
  render() {
    
    const session = this.props.session
    if(typeof(session.forceLogout)!="undefined" && session.forceLogout==1) {
      this.setState({session: null});
      window.location.href="/login";
    }

   const {
      title = 'benfido CLO',
      url = '/'
    } = this.props;
    const isLoggedIn = this.props.logged_in;
    let button;
    if (isLoggedIn === true || isLoggedIn.success) {
      button = <Link to="logout" className="nav-link login-btn" onClick={this.logOut}>Log out</Link>;
    } else {
      button = <Link to="login" className="nav-link logout-btn">Login</Link>;
    }
    if (isLoggedIn.error && !isLoggedIn.success) {
      alert(isLoggedIn.error);
    }
    if (isLoggedIn.success) {
      //window.location.replace("/");
    }

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
       <ThemeContext.Consumer>
      { theme => (
        <React.Fragment>
          <AppSidebarToggler className="d-lg-none" display="md" mobile />
          <AppNavbarBrand
            full={{ src: logo, width: 130, height: 30, alt: 'Benfido Logo' }}
            minimized={{ src: sygnet, width: 41, height: 30, alt: 'Benfido Logo' }}
          />
          <AppSidebarToggler className="d-md-down-none" display="lg" />
          <Nav className="d-md-down-none" navbar>
            <NavItem className="px-3">
              <NavLink to="/dashboard" className="nav-link">Dashboard</NavLink>
            </NavItem>
          </Nav>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <Countdown seconds={theme.seconds} />
            </NavItem>
            <NavItem className="theme-switcher">
            {theme.state.theme=='dark'
              ? <Brightness5SharpIcon onClick={this.switchDarkMode} />
              : <Brightness4RoundedIcon onClick={this.switchDarkMode} />
            }
            </NavItem>
            <UncontrolledDropdown nav direction="down">
              <DropdownToggle nav>
                <SimpleLineIcon name="user" size="medium" />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem header tag="div" className="text-center"><strong>Account</strong></DropdownItem>
                <DropdownItem><i className="fa fa-bell-o"></i> Updates<Badge color="info">42</Badge></DropdownItem>
                <DropdownItem><i className="fa fa-envelope-o"></i> Messages<Badge color="success">42</Badge></DropdownItem>
                <DropdownItem><i className="fa fa-tasks"></i> Tasks<Badge color="danger">42</Badge></DropdownItem>
                {/*
                <DropdownItem><i className="fa fa-lock" onClick={this.signOutTest}></i> <span  onClick={this.signOutTest}>Sign Out(forced)</span></DropdownItem>
                <DropdownItem><i className="fa fa-lock" onClick={this.refreshAccessToken}></i> <span  onClick={this.refreshAccessToken}>Refresh Access Token</span></DropdownItem>
                */}
                <DropdownItem><Link to="/logout" onClick={this.logOut}><i className="fa fa-lock" onClick={this.logOut}></i> Logout</Link></DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          <AppAsideToggler className="d-md-down-none" />

        </React.Fragment>
      )}
      </ThemeContext.Consumer>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

DefaultHeader.defaultProps = {
  title: 'Benfido'
}

DefaultHeader.propTypes = {
  actions: PropTypes.object.isRequired
}

function mapStateToProps(state, ownProps) {
  return {
    logged_in: state.session,
    session: state.session
    };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(sessionActions, dispatch)
  };
}
DefaultHeader.contextType = ThemeContext;
export default connect(mapStateToProps, mapDispatchToProps)(DefaultHeader);
