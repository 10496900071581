// Dependencies
import axios from 'axios';
import { API_URL, X_API_KEY, X_API_VERSION } from '../conf.js';
import { getCookie } from '../shared/utils/cookies';
import { axiosInterceptors } from '../shared/utils/axiosInterceptors';
import { objectToFormData } from 'object-to-formdata';

// Action Types
import {
  GET_CASHBACK_REQUEST,
  GET_CASHBACK_SUCCESS,
  GET_CASHBACK_ERROR,
  GET_CASHBACKS_REQUEST,
  GET_CASHBACKS_SUCCESS,
  GET_CASHBACKS_ERROR,
  ADD_CASHBACK_REQUEST,
  ADD_CASHBACK_SUCCESS,
  ADD_CASHBACK_ERROR,
  DELETE_CASHBACK_REQUEST,
  DELETE_CASHBACK_SUCCESS,
  DELETE_CASHBACK_ERROR,
  UPDATE_CASHBACK_REQUEST,
  UPDATE_CASHBACK_SUCCESS,
  UPDATE_CASHBACK_ERROR
} from './actionTypes';

// Base Actions
import { request, received, error } from '../shared/redux/baseActions';

const options = {
  /**
   * include array indices in FormData keys
   * defaults to false
   */
  indices: false,

  /**
   * treat null values like undefined values and ignore them
   * defaults to false
   */
  nullsAsUndefineds: false,

  /**
   * convert true or false to 1 or 0 respectively
   * defaults to false
   */
  booleansAsIntegers: false,
};

const getCashback = (params) => dispatch => {
  dispatch(request(GET_CASHBACK_REQUEST));
  var id = '';
  if (typeof (params) !== 'undefined') {
    id = params;
  }
  // Axios Data
  const axiosData = {
    method: 'GET',
    url: API_URL + '/cashbacks/' + id,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'X-Api-Version' : X_API_VERSION,
      'Authorization': 'Bearer ' + getCookie("_str")
    }
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(GET_CASHBACK_SUCCESS, response.data)))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(GET_CASHBACK_ERROR));
    });
};

const getCashbacks = (params) => dispatch => {
  dispatch(request(GET_CASHBACKS_REQUEST));
  var perPage = 10;
  var page = 1;
  console.log('GET_CASHBACKS');
  console.log(params);
  var url = API_URL + '/cashbacks?';
  var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
  console.log(queryString);
  url += queryString;
  // Axios Data
  const axiosData = {
    method: 'GET',
    url: url,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'X-Api-Version' : X_API_VERSION,
      'Authorization': 'Bearer ' + getCookie("_str")
    }
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(GET_CASHBACKS_SUCCESS, { items: response.data['items'], meta: response.data["meta"] })))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(GET_CASHBACKS_ERROR));
    });
};

const addCashback = (params) => dispatch => {
  dispatch(request(ADD_CASHBACK_REQUEST));
  var id = '';
  if (typeof (params) !== 'undefined') {
    id = params;
  }
  console.log('ADD_CASHBACK');
  console.log(params);
  params.address_attributes = params.address
  params.contact_attributes = params.contact
  // Axios Data
  const axiosData = {
    method: 'POST',
    url: API_URL + '/cashbacks',
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'X-Api-Key': X_API_KEY,
      'X-Api-Version' : X_API_VERSION,
      'Authorization': 'Bearer ' + getCookie("_str")
    },
    data: formData(params)
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(ADD_CASHBACK_SUCCESS, response.data['form'])))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(ADD_CASHBACK_ERROR, err.response));
    });
};

const updateCashback = (params) => dispatch => {
  dispatch(request(UPDATE_CASHBACK_REQUEST));
  var id = '';
  if (typeof (params) !== 'undefined') {
    id = params;
  }
  console.log('UPDATE_CASHBACK');
  console.log(params);
  params = { ...params, ...{ address_attributes: params.address } }
  params = { ...params, ...{ contact_attributes: params.contact } }
  // Axios Data
  const axiosData = {
    method: 'PATCH',
    url: API_URL + '/cashbacks/' + params.id,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'X-Api-Key': X_API_KEY,
      'X-Api-Version' : X_API_VERSION,
      'Authorization': 'Bearer ' + getCookie("_str")
    },
    data: formData(params)
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(UPDATE_CASHBACK_SUCCESS, response.data['form'])))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(UPDATE_CASHBACK_ERROR, err.response));
    });
};

const deleteCashback = (id) => dispatch => {
  dispatch(request(DELETE_CASHBACK_REQUEST));
  // Axios Data
  const axiosData = {
    method: 'DELETE',
    url: API_URL + '/cashbacks/' + id,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'X-Api-Version' : X_API_VERSION,
      'Authorization': 'Bearer ' + getCookie("_str")
    }
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(DELETE_CASHBACK_SUCCESS, response.data)))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(DELETE_CASHBACK_ERROR, err.response));
    });
};

const resetCashback = () => dispatch => {
  dispatch(received(ADD_CASHBACK_SUCCESS, []))
};

function formData(params) {
  if(params.logo!=null && typeof(params.logo)==='object') {
    params.logo = params.logo[0];
  }
  else {
    delete params.logo;
  }
  if(params.logo_2x!=null && typeof(params.logo_2x)==='object') {
    params.logo_2x = params.logo_2x[0];
  }
  else {
    delete params.logo_2x;
  }
  const formData = objectToFormData(
    params,
    options, // optional
    //existingFormData, // optional
    //keyPrefix, // optional
  );
  return formData;
}

export { getCashback, getCashbacks, addCashback, updateCashback, resetCashback, deleteCashback };
