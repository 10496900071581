 export const validate = values => {
 
  const errors = {}
  if (!values.title) {
    errors.title = 'Required';
  } else if (values.title.length > 35) {
    errors.title = 'Must be 35 characters or less'
  }
  if (!values.description) {
    errors.description = 'Required'
  }
  return errors
}