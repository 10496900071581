 export const validate = values => {

  const errors = {}
  
  if (!values.name) {
    errors.name = 'Required';
  }

  console.log('---errors');
  console.log(errors);

  return errors

}