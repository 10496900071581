import React from 'react';
import { Field, FieldArray } from 'redux-form'
import Checkbox from '@material-ui/core/Checkbox'
import { Radio, RadioGroup } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CheckIcon from '@material-ui/icons/Check';
import Multiselect from 'react-widgets/lib/Multiselect';
import Combobox from 'react-widgets/lib/Combobox';
import 'react-widgets/dist/css/react-widgets.css';


export const renderField = ({ input, label, type, placeholder, className, required, asterisk, defaultValue, value, disabled, meta: { touched, error } }) => (
  <div className="mb-2 mr-2">
    {label!=null &&
      <label>{label} {asterisk && <span className="text-danger">*</span>}</label>
    }
    <div>
      <input {...input} type={type} placeholder={placeholder} required={required} className={className} disabled={disabled} />
      {touched && error && <span className="field-error">{error}</span>}
    </div>
  </div>
)

export const renderTextareaField = ({ input, label, type, placeholder, className, required, asterisk, meta: { touched, error } }) => (
  <div className="mb-2 mr-2">
    <label>{label} {asterisk && <span className="text-danger">*</span>}</label>
    <div>
      <textarea {...input} placeholder={label} type={type} required={required} className={className}></textarea>
      {touched && error && <span className="field-error">{error}</span>}
    </div>
  </div>
)
  
export const renderSelectField = ({ input, label, required, placeholder, className, options, asterisk, defaultValue, meta: { touched, error } }) => (
  <div className="mb-2 mr-2">
    <label>{label} {asterisk && <span className="text-danger">*</span>}</label>
    <div>
      <select {...input} required={required} className={className} value={defaultValue} >
            <option value="" key={input.name+'0'}>---</option>
            {options.map(option => {
                return (
                  <option value={option.value} key={input.name+''+option.value}>{option.text}</option>
                )
              })
            }
      </select>
      {touched && error && <span className="field-error">{error}</span>}
    </div>
  </div>
)

export const renderCheckbox = ({ input, name, label, color, asterisk }) => (
  <div>
    <FormControlLabel
      control={
        <Checkbox
          name={name}
          checked={input.value ? true : false}
          onChange={input.onChange}
          color={color}
        />
      }
      label={label}
    />
  </div>
)

export const renderMultipleCheckbox = ({ input, name, val, label, color, asterisk, checked }) => (
  <div>
    <FormControlLabel
      control={
        <Checkbox
          name={name}
          value={val}
          checked={checked}
          onChange={input.onChange}
          color={color}
          icon={<CheckIcon />} 
          checkedIcon={<CheckIcon />}
        />
      }
      label={label}
    />
  </div>
)

export const renderDateField = ({ input, label, type, placeholder, className, required, asterisk, meta: { touched, error } }) => (
  <div className="mb-2 mr-2">
    <label>{label} {asterisk && <span className="text-danger">*</span>}</label>
    <div>
      <input {...input} value={input.value!='' ? input.value.substring(0,10) : ''} type={type} placeholder={placeholder} required={required} className={className} />
      {touched && error && <span className="field-error">{error}</span>}
    </div>
  </div>
)


export const renderMultiselect = ({ input, data, valueField, textField, filter, disabled, onSearch, messages, meta: { touched, error } }) =>
  <div>
    <Multiselect {...input}
      onBlur={() => input.onBlur()}
      value={input.value || []} // requires value to be an array
      data={data}
      valueField={valueField}
      textField={textField}
      filter={filter}
      onSearch={onSearch}
      messages={messages}
      disabled={disabled}
    />
   {touched && error && <span className="field-error">{error}</span>}
  </div>

export const renderCombobox = ({ input, data, valueField, textField, filter, disabled, onSearch, messages, className, asterisk, label, meta: {touched, error} }) =>
  <div className="mb-2">
    <label>{label} {asterisk && <span className="text-danger">*</span>}</label>
    <Combobox {...input}
      onBlur={() => input.onBlur()}
      value={input.value || []} // requires value to be an array
      data={data}
      valueField={valueField}
      textField={textField}
      filter={filter}
      messages={messages}
      disabled={disabled}
      className={className}
    />
    {touched && error && <span className="field-error">{error}</span>}
  </div>


export const renderRadioGroup = ({ input, defaultValue, ...rest }) => (
  <RadioGroup
    {...input}
    {...rest}
    value={defaultValue}
    onChange={(event, value) => input.onChange(value)}
  />
);

