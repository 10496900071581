import * as types from '../actions/actionTypes';

//import {browserHistory} from 'react-router';

// Utils
import { getNewState } from '../shared/utils/frontend';

const initialState = {};

export default function analyticReducer(state = initialState, action) {
  switch(action.type) {
    
    case types.GET_ANALYTIC_CHART_DATA_SUCCESS:
    {
      const {payload: result} = action;
      console.log('ACTION');
      console.log(action);
      return getNewState(state, {result});
    }
    break;
    
    case types.GET_ANALYTIC_CHART_DATA_ERROR:
    {
      return Object.assign({}, state, {error: action.payload});
    }
    break;

    case types.GET_REDASH_JOB_SUCCESS:
    {
      const {payload: result} = action;
      console.log('ACTION');
      console.log(action);
      return getNewState(state, {result});
    }
    break;  

    case types.GET_REDASH_JOB_ERROR:
    {
      return Object.assign({}, state, {error: action.payload});
    }
    break;

    case types.GET_REDASH_QUERY_RESULTS_SUCCESS:
    {
      const {payload: result} = action;
      console.log('ACTION');
      console.log(action);
      return getNewState(state, {result});
    }
    break;  

    case types.GET_REDASH_QUERY_RESULTS_ERROR:
    {
      return Object.assign({}, state, {error: action.payload});
    }
    break;

    default: 
      return state;
  
  }
}

