import React from 'react';
import { Field, FormSection, reduxForm } from 'redux-form'
import { renderField, renderTextareaField, renderSelectField, renderCheckbox, renderDateField, renderMembers, renderCombobox, renderMultiselect } from '../../shared/components/form-field/ReduxFormFields';
import { getDates } from '../../shared/utils/helpers.js';

class Widget extends React.Component {

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <h3>{this.props.title}</h3>
          <h2>{this.props.content}</h2>
          <h4>{this.props.footer}</h4>
        </div>
      </div>
    )
  }

}

class WidgetTwo extends React.Component {

  formatNumber(number, currency) {
    if(typeof(currency)!==undefined) {
     // return new Intl.NumberFormat('de-DE', {maximumFractionDigits: 0,}).format(number);
     return number.toLocaleString(undefined, {minimumFractionDigits: number<1 ? 2 : 1, maximumFractionDigits: number<1 ? 2 : 1});
    }
    else {
      return number.toLocaleString();
    }
  }

  render() {
    return (
      <div className="card text-center">
        <div className="card-header"><h5>{this.props.title}</h5></div>
        <div className="card-body">
        <div className="row">
          <div className="col">
            <div className="text-value-xl">{this.props.prefix}{this.formatNumber(this.props.value1, true)}{this.props.suffix} 
              {parseFloat(this.props.value1) > parseFloat(this.props.value2) &&
                <span className="text-success"> &uarr;</span> 
              }
              {parseFloat(this.props.value1) < parseFloat(this.props.value2) &&  
                <span className="text-danger"> &darr;</span>
              }
            </div>
            <div className="text-uppercase text-muted small">current period</div>
            </div>
            <div className="c-vr"></div>
            <div className="col">
              <div className="text-value-xl">{this.props.prefix}{this.formatNumber(this.props.value2, true)}{this.props.suffix}</div>
              <div className="text-uppercase text-muted small">prev period</div>
            </div>
            {/*
              <h3>{this.props.title}</h3>
              <h2>{this.props.content}</h2>
              <h4>{this.props.footer}</h4>
            */}
          </div>
        </div>
      </div>
    )
  }
}

class AnalyticFilterForm extends React.Component {

  constructor(props) {
    super(props);
    this.handleFilterFieldChange = this.handleFilterFieldChange.bind(this);
    var dates = getDates();
    var bank_id = ((new URLSearchParams(window.location.search)).get("bank_id"));
    var booked_at_start = ((new URLSearchParams(window.location.search)).get("booked_at_start"));
    var booked_at_end = ((new URLSearchParams(window.location.search)).get("booked_at_end"));
    this.state = {
      bank_id: bank_id ? bank_id : null,
      booked_at_start: booked_at_start ? booked_at_start : dates.startDate,
      booked_at_end: booked_at_end ? booked_at_end : dates.endDate
    }
  } 

  componentDidMount() {
    if(this.state.bank_id!=null && this.state.bank_id!='0') {
      this.props.setFilterParams(this.state);
    }
  }

  handleFilterFieldChange(e) {
    this.setState({[e.target.name]: e.target.value}, () => {
      if( (Date.parse(this.state.booked_at_start) <= Date.parse(this.state.booked_at_end)) && this.state.bank_id!=null && this.state.bank_id!='0') {
        this.props.setFilterParams(this.state);
        this.props.history.push({
            pathname: '/analytic/'+this.props.location.pathname.split('/').pop(),
            search: "?" + new URLSearchParams(this.state).toString()
        });
      }
      else {
        alert('Incorrect filter values!');
      }
    });
 } 

  render() {
    return(
      <div className="d-flex mb-3">
        <div className="form-group mr-2">
          <label>Choose Bank:</label>
          <select type="date" className="form-control" name="bank_id" value={this.state.bank_id} onChange={(e)=>{this.handleFilterFieldChange(e)}}>
            <option value="0">---</option>
            <option value="1">DKB</option>
          </select>
        </div>
        <div className="form-group mr-2">
          <label>Date from:</label>
          <input type="date" placeholder="Date from" className="form-control" value={this.state.booked_at_start} name="booked_at_start" onChange={(e)=>{this.handleFilterFieldChange(e)}}/>
        </div>
        <div className="form-group mr-2">
          <label>Date to:</label>
          <input type="date" placeholder="Date to" className="form-control" value={this.state.booked_at_end} name="booked_at_end" onChange={(e)=>{this.handleFilterFieldChange(e)}}/>
        </div>
      </div>
    )
  }
  
}

export {Widget, WidgetTwo, AnalyticFilterForm};