import React, { Component } from 'react';

import moment from 'moment';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Line, Doughnut, Bar, Chart } from 'react-chartjs-2';
import 'chartjs-plugin-labels';
//import 'chartjs-plugin-datalabels';

import { Widget, AnalyticFilterForm } from './Widget';
import { optionsLight, optionsDark, lineDatasetProps, doughnutOptions, barOptionsLight, barOptionsDark } from '../../conf.js';
// Actions
import { checkAuth } from '../../actions/sessionActions';
import { getAnalyticChartData, getRedashJob, getRedashQueryResults } from '../../actions/analyticActions';

import { arrayComparer } from '../../shared/utils/helpers.js';

import { UserContext } from '../../shared/context/user';
import { ThemeContext } from '../../shared/context/theme';

// Redux Store
import configureStore from '../../shared/redux/configureStore';
// Configuring Redux Store
const store = configureStore(window.initialState);

var colors = ["#457b9d","#a8dadc","#f1faee","#f91f43","#e76f51","#f4a261","#e9c46a","#f4e3b8", "#2A9D8F","#0f358e","#1d3557"];

var fillScatteredTimeScaleDataPlugin = {
  beforeUpdate: function(c) {
    var timeAxis = c.options.scales.xAxes[0].time;
    if (!timeAxis || !timeAxis.fillGapsWithZero) return;
    for (var i=0;i<c.data.datasets.length;i++){
      var set = c.data.datasets[i];
      if (!set.data.length) continue;
      var min, max, hash = {};
      for (var j=0;j<set.data.length;j++){
        var val = moment(set.data[j].x, timeAxis.parser);
        if (!min || min.diff(val)>0)
          min = val;
        if (!max || max.diff(val)<0)
          max = val;
        hash[set.data[j].x] = 1;
      }
      for (var val = min; max.diff(val)>0; val.add(1, timeAxis.minUnit)){
        var d = val.format(timeAxis.parser);
        if (!hash[d])
          set.data.push({x:d, y:0});
      }
      set.data.sort(function(a,b){
        return a.x < b.x?-1:1;
      });
    }
  }
}

const charts = [
  {
    identifier: 'noOfTransactions',
    label: 'No of Transactions',
    type: 'line'
  }, 
  {
    identifier: 'noOfRegUsers',
    label: 'No of Registered Users',
    type: 'line'
  }, 
  {
    identifier: 'noPerActivityGroup',
    label: 'User Activity Groups',
    type: 'line'
  }, 
  {
    identifier: 'noOfActiveUsers',
    label: 'Active Users',
    type: 'line'
  }, 
  {
    identifier: 'percPerActiveUsersByGender',
    label: 'Active Users By Gender',
    type: 'donut'
  }, 
  {
    identifier: 'percOfAlltimeAct',
    label: 'All Time Activity',
    type: 'donut'
  },
  {
    identifier: 'percPerActiveUsersByAge',
    label: 'Active Users By Age',
    type: 'donut'
  },
  {
    identifier: 'Top100CustomersByPaymentTurnover',
    label: 'Top 100 Customers By Payment Turnover',
    type: 'bar'
  },
  {
    identifier: 'Top10CategoryByPaymentTurnover',
    label: 'Top 10 Category By Payment Turnover',
    type: 'bar'
  },
  {
    identifier: 'PaymentMediumPopByTurnover',
    label: 'Payment Medium Pop By Turnover',
    type: 'donut'
  },
  {
    identifier: 'Top10BrandsByPaymentTurnover',
    label: 'Top 10 Brands By Payment Turnover',
    type: 'bar'
  },
  {
    identifier: 'percCancelRatios',
    label: 'Cancellation Ratios',
    type: 'line'
  }
];

class Performance extends Component {
  
  constructor(props) {
    super(props);
    var options = optionsLight;
    var barOptions = barOptionsLight;
    if(localStorage.getItem('theme')=='dark') {
      options = optionsDark;
      barOptions = barOptionsDark;
    }
    this.state = {
      theme: props.theme,
      lineOptions: options,
      barOptions: barOptions,
      lineData: [],
      loadedCharts: []
    };
    this.setFilterParams = this.setFilterParams.bind(this);
    this.drawChart = this.drawCharts.bind(this);
    charts.map((chart)=>{
      this.state[chart.identifier+"Data"] = [];
      this.state[chart.identifier+"Redraw"] = false;
      this[chart.identifier+"Reference"] = React.createRef();
    });
  }

  componentDidMount() {
    alert('Performance Component');
    //this.setState({theme: this.context.theme.theme});
  }

  componentDidUpdate(prevProps, prevState) {
  
    if(this.state.theme != this.context.state.theme) {
      let theme = this.context.state.theme;
      let lineOptions;
      let barOptions;
      if(this.context.state.theme=='dark') {
        lineOptions = optionsDark;
        barOptions = barOptionsDark;  
      }
      else {
        lineOptions = optionsLight; 
        barOptions = barOptionsLight;
      }
      let stateUpdates = {theme: this.context.state.theme};
      stateUpdates = Object.assign({lineOptions: lineOptions, barOptions: barOptions}, stateUpdates);
      this.setState(stateUpdates);
      //alert(JSON.stringify(stateUpdates));
    }  

    charts.filter((chart)=>['donut', 'bar', 'line'].indexOf(chart.type)!=-1).map((chart) => {
      if(typeof(this[chart.identifier+"Reference"])!='undefined') {
        let chartInstance = this[chart.identifier+"Reference"].current.chartInstance;
        if(typeof(chartInstance.chart.config.data.datasets[0])!='undefined') {
          chartInstance.chart.config.data.datasets[0].borderWidth = chart.type=='donut' ? 4 : 0;
          chartInstance.chart.config.data.datasets[0].borderColor = this.context.state.theme=='dark' ? '#3B3D47' : '#ffffff';

          // fillGapsWithZero
          if(chart.type=='line') {
            var c = chartInstance.chart;
            var timeAxis = c.options.scales.xAxes[0].time;
            if(typeof(timeAxis)!='undefined') {
              if (!timeAxis || !timeAxis.fillGapsWithZero) return;
              for (var i=0;i<c.data.datasets.length;i++){
                var set = c.data.datasets[i];
                if (!set.data.length) continue;
                var min, max, hash = {};
                for (var j=0;j<set.data.length;j++){
                  var val = moment(set.data[j].t, timeAxis.parser);
                  if (!min || min.diff(val)>0)
                    min = val;
                  if (!max || max.diff(val)<0)
                    max = val;
                  hash[set.data[j].t] = 1;
                }
                for (var val = min; max.diff(val)>0; val.add(1, timeAxis.minUnit)){
                  var d = val.format(timeAxis.parser);
                  if (!hash[d])
                    set.data.push({t:d, y:0});
                }
                set.data.sort(function(a,b){
                  return a.t < b.t?-1:1;
                });
              }
            }
          }

          chartInstance.update();
          //window[redrawChart] = true;
        }
      }
    });
  
  }

  setFilterParams(params) {
    console.log('---setFilterParams');
    console.log(params);
    this.getAnalyticData(params);    
  }

  getAnalyticData(params) {
    params.queryId = 6;
    params.time_dimension = 'day';
    store.dispatch(getAnalyticChartData(params)).then((result)=>{
      if(typeof(result.payload.query_result)!='undefined') {
        this.drawCharts(result.payload.query_result);
      }
      else {
        //window.location.reload();
        if(typeof(result.payload.job)!='undefined') {
          this.getRedashJob(result.payload.job.id);      
        }
      }
    }).catch((error)=>{
      alert(error);
    });
  }

  getRedashJob(id) {
    store.dispatch(getRedashJob(id)).then((result)=>{
      //console.log(result);
      if(typeof(result.payload.job.query_result_id)!='undefined') {
        this.getRedashQueryRes(result.payload.job.query_result_id);
      }
    }).catch((error)=>{
      alert(error);
    });
  }

  getRedashQueryRes(id) {
    store.dispatch(getRedashQueryResults(id)).then((result)=>{
      //console.log(result);
      if(typeof(result.payload.query_result)!='undefined') {
        this.drawCharts(result.payload.query_result);
      }
    }).catch((error)=>{
      alert(error);
    });
  }

  drawCharts(queryResult) {

    var data = [];
    if(typeof(queryResult.data.rows)!='undefined') {
      data = queryResult.data.rows;
    } 
    charts.map((chart)=>{

      console.log('---drawCharts');
      console.log(chart);
      console.log(data);
      var curData = {};
      curData.datasets = [];
      var redrawChart = chart.identifier+"Redraw";
      window[redrawChart] = false;
      var availableCharts = [];
      if(typeof(data[0].json_text)!='undefined' && data[0].json_text!=null) {
        availableCharts = Object.keys(data[0].json_text);  
      }
      if(data[0].json_text!=null) {
        var rows = [];
        if(typeof(data[0].json_text[chart.identifier])!='undefined') {
          rows = data[0].json_text[chart.identifier].rows;
        }
        window[redrawChart] = this.state[chart.identifier+"Data"].datasets.length<rows.length;
        for(var i=0; i<rows.length; i++) {
          var dataset = {}
          dataset.label = rows[i].tag;
          dataset.borderColor = colors[i];
          dataset.pointBorderColor = colors[i];
          if(chart.type=='donut') {
            curData.labels = typeof(curData.labels)=='undefined' ? [rows[i].tag] : curData.labels.concat(rows[i].tag);
            curData.datasets = curData.datasets.length==0 ? [{backgroundColor: [colors[i]], borderColor: [this.context.state.theme=='dark' ? '#3B3D47' : '#ffffff'], borderWidth: 2, data: [rows[i].data[0].y]}] : [{backgroundColor: curData.datasets[0].backgroundColor.concat(colors[i]), borderColor: curData.datasets[0].borderColor.concat(this.context.state.theme=='dark' ? '#3B3D47' : '#ffffff'), borderWidth: 2, data: curData.datasets[0].data.concat(rows[i].data[0].y)}];
            //let chartInstance = this[chart.identifier+"Reference"].current.chartInstance;
            //chartInstance.chart.config.data.datasets[0].borderWidth = 10;
            //chartInstance.update();
            //window[redrawChart] = true;
          }
          else if(chart.type=='bar') {
            curData.type = 'bar';
            curData.labels = typeof(curData.labels)=='undefined' ? rows[i].data.map((el)=>el.t) : curData.labels.concat(rows[i].data.map((el)=>el.t));
            curData.datasets = curData.datasets.length==0 ? [{label: rows[i].tag, barPercentage: 0.5, backgroundColor_: [colors[i]], borderColor: [this.context.state.theme=='dark' ? '#3B3D47' : '#ffffff'], borderWidth: 2, data: rows[i].data.map((el)=>el.y) }] : [{label: rows[i].tag, barPercentage: 0.5, backgroundColor_: curData.datasets[0].backgroundColor_.concat(colors[i]), borderColor: curData.datasets[0].borderColor.concat(this.context.state.theme=='dark' ? '#3B3D47' : '#ffffff'), borderWidth: 2, data: curData.datasets[0].data.concat(rows[i].data.mat((el)=>el.y))}];
            //let chartInstance = this[chart.identifier+"Reference"].current.chartInstance;
            //chartInstance.chart.config.data.datasets[0].borderWidth = 10;
            //chartInstance.update();
            //window[redrawChart] = true;
          }
          else {
            dataset.data = rows[i].data;
            curData.datasets.push({...lineDatasetProps, ...dataset});
          }
          
        }
        //let noOfTransactionsChart = this.noOfTransactionsReference.current.chartInstance;
        //noOfTransactionsChart.update();
      }
      var loadedCharts = this.state.loadedCharts;
      var onlyInA = loadedCharts.filter(arrayComparer(availableCharts));
      for(var i=0; i<onlyInA.length; i++) {
        //alert(this[onlyInA[i]+"Reference"].current.chartInstance);
        //this[onlyInA[i]+"Reference"].current.chartInstance.config.data = {datasets: []};
        this[onlyInA[i]+"Reference"].current.chartInstance.reset();
      }
      var onlyInB = availableCharts.filter(arrayComparer(loadedCharts));
      var chartsDiff = onlyInA.concat(onlyInB);
      console.log('---curData');
      console.log(curData);

      this.setState({[chart.identifier+"Data"]: curData, noOfTransactionsRedraw: window[redrawChart], loadedCharts: availableCharts}, ()=>{console.log(this.state)});
  
    });

  }

  render() {
    
    console.log('---render');
    const session = this.props.session
    if(typeof(session.forceLogout)!="undefined" && session.forceLogout==1) {
      this.setState({session: null});
      window.location.href="/login";
    }

    return (
      <UserContext.Consumer>
      { user => (
      <div className="analytic mb-5">
        <div className="row">
          <div className="col-12"><AnalyticFilterForm {...this.props} setFilterParams={this.setFilterParams} /></div>
          
          {charts.map((chart)=>{
            return (
              <div className="col-12 col-sm-6">
                <div className="panel-filled">           
                  <h5 className="mb-3">{chart.label}</h5>
                  {chart.type == 'donut' &&
                    <Doughnut
                      data={this.state[chart.identifier+"Data"]}
                      width={100}
                      height={35}
                      options={doughnutOptions}
                      ref={this[chart.identifier+"Reference"]}
                      redraw={this.state[chart.identifier+"Redraw"]}
                    />
                  }
                  {chart.type == 'line' &&
                    <Line
                      data={this.state[chart.identifier+"Data"]}
                      width={100}
                      height={35}
                      options={this.state.lineOptions}
                      ref={this[chart.identifier+"Reference"]}
                      redraw={this.state[chart.identifier+"Redraw"]}
                    />
                  }
                  {chart.type == 'bar' &&
                    <Bar
                      data={this.state[chart.identifier+"Data"]}
                      width={100}
                      height={35}
                      options={this.state.barOptions}
                      ref={this[chart.identifier+"Reference"]}
                      redraw={this.state[chart.identifier+"Redraw"]}
                    />
                  }
                </div>
              </div>
            )
          })}

          {/*
          <div className="col-12 col-sm-6">
            <div className="panel-filled">           
              <h5 className="mb-3">No of Transactions</h5>
              <Line
                data={this.state.noOfTransactionsData}
                width={100}
                height={35}
                options={this.state.lineOptions}
                ref={this.noOfTransactionsReference}
                redraw={this.state.noOfTransactionsRedraw}
              />
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="panel-filled">           
              <h5 className="mb-3">No of Registered Users</h5>
              <Line
                data={this.state.noOfRegUsersData}
                width={100}
                height={35}
                options={this.state.lineOptions}
                ref={this.noOfRegUsersReference}
                redraw={this.state.noOfRegUsersRedraw}
              />
            </div>
          </div>
          */}          
          <div className="col-12 col-sm-6">
            <div className="panel-filled">           
              <h5 className="mb-3">Turnover</h5>
              <Line
                data={this.state.lineData}
                width={100}
                height={35}
                options={this.state.lineOptions}
              />
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="panel-filled">           
              <h5 className="mb-3">Cashback Transactions by Status</h5>
              <Line
                data={this.state.lineData}
                width={100}
                height={35}
                options={this.state.lineOptions}
              />
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="panel-filled">           
              <h5 className="mb-3">Cashback Turnover by Status</h5>
              <Line
                data={this.state.lineData}
                width={100}
                height={35}
                options={this.state.lineOptions}
              />
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="panel-filled">           
              <h5 className="mb-3">Active Campaigns</h5>
              <Line
                data={this.state.lineData}
                width={100}
                height={35}
                options={this.state.lineOptions}
              />
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="panel-filled">           
              <h5 className="mb-3">Actie Targeted Campaigns</h5>
              <Line
                data={this.state.lineData}
                width={100}
                height={35}
                options={this.state.lineOptions}
              />
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="panel-filled">           
              <h5 className="mb-3">Cashback in €</h5>
              <Line
                data={this.state.lineData}
                width={100}
                height={35}
                options={this.state.lineOptions}
              />
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="panel-filled">           
              <h5 className="mb-3">Coupon Clicks</h5>
              <Line
                data={this.state.lineData}
                width={100}
                height={35}
                options={this.state.lineOptions}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-4 text-center">
            <Widget title="Turnover per Customer" content="€ 12.00" /> 
          </div>
           <div className="col-12 col-sm-4 text-center">
            <Widget title="Turnover per Transaction" content="€ 1.01" /> 
          </div>
           <div className="col-12 col-sm-4 text-center">
            <Widget title="Cashback per Transaction" content="€ 1.42" /> 
          </div>
        </div>
      </div>
      )}
      </UserContext.Consumer>
    );
  }
}

function mapStateToProps(state) {
    return {
      isMobile: state.device.isMobile,
      session: state.session      
    }
  };

const mapDispatchToProps = dispatch => bindActionCreators({checkAuth}, dispatch);
Performance.contextType = ThemeContext;
export default connect(
        mapStateToProps,
        mapDispatchToProps
        )(Performance);
