// Action Types
import {
        SET_START_DATE, SET_END_DATE, SET_BANK_ID
        } from '../actions/actionTypes';

// Utils
import { getNewState } from '../shared/utils/frontend';

// Initial State
const initialState = {
  startDate: null,
  endDate: null,
  bankId: null
};

export default function globalParamsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_START_DATE:
    {
      const startDate = action.payload;
      console.log('ACTION');
      console.log(action);
      return getNewState(state, {
        startDate
      });
    }
    break;

    case SET_END_DATE:
    {
      const endDate = action.payload;
      console.log('ACTION');
      console.log(action);
      return getNewState(state, {
        endDate
      });
    }
    break;

    case SET_BANK_ID:
    {
      const bankId = action.payload;
      console.log('ACTION');
      console.log(action);
      return getNewState(state, {
        bankId
      });
    }
    break;
    
  default:
      return state;
  } 
};