import React, {Component} from 'react';
import { Field, FieldArray } from 'redux-form'
import { Form, Input, Button, Card, CardTitle, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { renderField, renderCheckbox  } from '../form-field/ReduxFormFields';

const renderSubFields = (member, index, fields) => (
  <div key={index} className="d-flex align-items-end">
    <Field
      name={`${member}`}
      type="text"
      component={renderField}
      className="form-control"
    />
    <div className="mb-2">
      <button
        type="button"
        title="Remove Keyword"
        onClick={() => { if(window.confirm('Are you sure you want to remove?')) { fields.remove(index)}}}
        className="btn btn-light btn-sm mb-1"
      >
        <i className="cil-x"></i>
      </button>
    </div>
  </div>
)
const renderMembers = ({ fields }) => (
  <div className="d-flex flex-column">
    <div>
      <a type="button" className="btn-link d-inline-block mb-3" onClick={() => fields.splice(0,0,'')}>
        <i className="cil-plus"></i> Add Keyword
      </a>
    </div>
    {fields.map(renderSubFields)}
  </div>
)  
class Keyword extends Component {

  render() {

    return (
      <Card>
        <CardBody>
          <CardTitle className="d-inline">Keywords</CardTitle>
          <FieldArray name="keywords" component={renderMembers} />
        </CardBody>
      </Card>
    )
  }
}

export default Keyword;