 export const validate = values => {

  
  if(values.address==null || typeof(values.address)=='undefined') {
    var address = {
      title: null,
      street: null,
      zip: null,
      city: null,
      country: null
    }
    values.address = address;
  }

  const validateAddress = (address) => {
    let errors = {};
    /*
    if (!address.title) {
      errors.title = 'Required';
    }
    if (!address.street) {
      errors.street = 'Required';
    }
    if (!address.city) {
      errors.city = 'Required';
    }
    if (!address.country) {
      errors.country = 'Required';
    }
    */
    return errors;
  }

  if(values.contact==null || typeof(values.contact)=='undefined') {
    var contact = {
      position: null,
      salutation: null,
      first_name: null,
      last_name: null,
      email: null,
      phone: null,
      fax: null
    }
    values.contact = contact;
  }

  const validateContact = (contact) => {
    let errors = {};
    /*
    if (!contact.position) {
      errors.position = 'Required';
    }
    if (!contact.first_name) {
      errors.first_name = 'Required';
    }
    if (!contact.last_name) {
      errors.last_name = 'Required';
    }
    if (!contact.email) {
      errors.email = 'Required';
    }
    if (!contact.phone) {
      errors.phone = 'Required';
    }
    */
    return errors;
  }


  const errors = {}
  if (!values.name) {
    errors.name = 'Required';
  } else if (values.name.length > 15) {
    errors.name = 'Must be 15 characters or less'
  }
  if (!values.identifier) {
    errors.identifier = 'Required';
  }
  if (!values.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }
  if (!values.age) {
    errors.age = 'Required'
  } else if (isNaN(Number(values.age))) {
    errors.age = 'Must be a number'
  } else if (Number(values.age) < 18) {
    errors.age = 'Sorry, you must be at least 18 years old'
  }

  /*
  if(values["address"] && typeof(values["address"])!="undefined" ) { 
    errors.address = {}
    if(values.address["title"]=='') {
      errors.address.title = 'Required';
    }
    if(values.address["country"]=='') {
      errors.address.country = 'Required';
    }    
  }
  
  if(values["contact"] && typeof(values["contact"])!="undefined" ) { 
    errors.contact = {}
    if(values.contact["first_name"]=='') {
      errors.contact.first_name = 'Required';
    }
    if(values.contact["last_name"]=='') {
      errors.contact.last_name = 'Required';
    }    
  }
  */

  errors.address = validateAddress(values.address);
  errors.contact = validateContact(values.contact);

  return errors
}