 export const validate = values => {

  if(values.categories==null || typeof(values.categories)=='undefined') {
    var categories = [];
    values.categories = categories;
  }
  const validateCategories = (categories) => {
    if (categories.length==0) {
      errors.categories = 'Required';
      return 'Required';
    }
    
  }

  if(values.aliases==null || typeof(values.aliases)=='undefined') {
    var aliases = [];
    values.aliases = aliases;
  }
  const validateAliases = (aliases) => {
    if (aliases.length==0) {
      errors.aliases = 'Required';
      return 'Required';
    } 
  }

  
  if(values.address==null || typeof(values.address)=='undefined') {
    var address = {
      title: null,
      street: null,
      zip: null,
      city: null,
      country: null
    }
    values.address = address;
  }

  const validateAddress = (address) => {
    let errors = {};
    /*
    if (!address.title) {
      errors.title = 'Required';
    }
    if (!address.street) {
      errors.street = 'Required';
    }
    if (!address.city) {
      errors.city = 'Required';
    }
    if (!address.country) {
      errors.country = 'Required';
    }
    */
    return errors;
  }

  if(values.contact==null || typeof(values.contact)=='undefined') {
    var contact = {
      position: null,
      title: null,
      first_name: null,
      last_name: null,
      email: null,
      phone: null,
      fax: null
    }
    values.contact = contact;
  }

  const validateContact = (contact) => {
    let errors = {};
    /*
    if (!contact.position) {
      errors.position = 'Required';
    }
    if (!contact.first_name) {
      errors.first_name = 'Required';
    }
    if (!contact.last_name) {
      errors.last_name = 'Required';
    }
    if (!contact.email) {
      errors.email = 'Required';
    }
    if (!contact.phone) {
      errors.phone = 'Required';
    }
    */
    return errors;
  }


  const errors = {}
  
  if (!values.aliases || !values.aliases.length) {
    errors.aliases = { _error: 'At least one alias must be entered' }
  } else {
    const aliasesMembersArrayErrors = []
    values.aliases.forEach((alias, aliasMemberIndex) => {
      const aliasMemberErrors = {}
      if (!alias || !alias.name) {
        aliasMemberErrors.name = 'Required'
        aliasesMembersArrayErrors[aliasMemberIndex] = aliasMemberErrors
      }
    })
    if (aliasesMembersArrayErrors.length) {
      errors.aliases = aliasesMembersArrayErrors
    }
  }
  if (!values.name) {
    errors.name = 'Required';
  }
 
  //errors.aliases = validateAliases(values.aliases);
  errors.categories = validateCategories(values.categories);
  errors.address = validateAddress(values.address);
  errors.contact = validateContact(values.contact);

  console.log('---errors');
  console.log(errors);

  return errors
}