// Action Types
import {
        GET_CASHBACK_SUCCESS, GET_CASHBACKS_SUCCESS, ADD_CASHBACK_SUCCESS
        } from '../actions/actionTypes';

// Utils
import { getNewState } from '../shared/utils/frontend';

// Initial State
const initialState = {};

export default function cashbackReducer(state = initialState, action) {
  switch (action.type) {
    
    /*
    case GET_CASHBACK_SUCCESS:
    {
      const {payload: cashback} = action;
      console.log('ACTION');
      console.log(action);
      return getNewState(state, {
        cashback
      });
    }
    break;
    */
    
    case GET_CASHBACKS_SUCCESS:
    {
      const {payload: result} = action;
      console.log('ACTION');
      console.log(action);
      return getNewState(state, {
        result
      });
    }
    break;
    
    /*
    case ADD_CASHBACK_SUCCESS:
    {
      const {payload: cashbacks} = action;
      return getNewState(state, {
        cashbacks
      });
    }
    break;
    */
 
  default:
      return state;
}
};