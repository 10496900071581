import React, {Component} from 'react';
import debounce from 'lodash/debounce';
import { PropTypes, array, bool } from 'prop-types';
import { Field, FieldArray, FormSection, reduxForm } from 'redux-form'
import Address from '../../shared/components/form-section/Address';
import Contact from '../../shared/components/form-section/Contact';
import Shipping from '../../shared/components/form-section/Shipping';
import BrandAlias from '../../shared/components/form-section/BrandAlias';
import Tag from '../../shared/components/form-section/Tag';

import { Form, Input, Button, Card, CardColumns, CardTitle, CardBody, CardHeader, Col, Row, Label } from 'reactstrap';
import Snackbar from '@material-ui/core/Snackbar';

import SimpleLineIcon from 'react-simple-line-icons';
import { renderField, renderTextareaField, renderSelectField, renderCheckbox, renderMultipleCheckbox, renderMembers, renderMultiselect } from '../../shared/components/form-field/ReduxFormFields';
import { UploadFile } from '../../shared/components/form-field/UploadFile';

import { reset } from 'redux-form';
import { SubmissionError } from 'redux-form'
import { stopSubmit } from 'redux-form'
import { change } from "redux-form";

import { getBrand, addBrand, updateBrand, resetBrand } from '../../actions/brandActions'; 
import { getCategories } from '../../actions/categoryActions';
import { getCountries } from '../../actions/countryActions';
import { getContractTypes } from '../../actions/contractTypeActions';
import { checkAuth } from '../../actions/sessionActions';
import { validate } from './validate';

import './BrandForm.css';

import { comparer } from '../../shared/utils/helpers.js';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Redux Store
import configureStore from '../../shared/redux/configureStore';
// Configuring Redux Store
const store = configureStore(window.initialState);

const messages = {
  emptyFilterList: 'No results. Try another query'
}

const renderAliasSubFields = (member, index, fields) => (
  <div key={index} className="d-flex align-items-end">
    <Field
      name={`${member}.id`}
      type="text"
      component={renderField}
      label={null}
      className="form-control d-none"
      disabled={true}
    />
    <Field
      name={`${member}.name`}
      type="text"
      component={renderField}
      label="Name"
      className="form-control"
    />
    <div className="mb-2">
      <button
        type="button"
        title="Remove BrandAlias"
        onClick={() => { if(window.confirm('Are you sure you want to remove?')) { 
          fields.remove(index); 
        }}}
        className="btn btn-light btn-sm mb-1"
      >
        <i className="cil-x"></i>
      </button>
    </div>
  </div>
)
const renderAliasMembers = ({ fields, meta: { error, submitFailed } }) => (
  <div className="d-flex flex-column mr-0 aliases-column">
    {fields.map(renderAliasSubFields)}
    <div className="mb-1 ml-2 mt-2">
      <a type="button" className="btn-link d-inline-block" onClick={() => fields.push({})}>
        <i className="cil-plus"></i> Add Alias
      </a>
      {submitFailed && error && <span className="field-error d-block pl-2">{error}</span>}
    </div>
  </div>
) 


class BrandForm extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      open: false,
      create: false,
      clearChangesEnabled: true,
      categories: null,
      countries: null,
      contractTypes: null,
      countrySearchResult: null,
      categorySearchResult: null,
      contractTypeSearchResult: null
    }
    this.submit = this.submit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCountrySearch = this.handleCountrySearch.bind(this);
    this.handleCategorySearch = this.handleCategorySearch.bind(this);
    this.handleContractTypeSearch = this.handleContractTypeSearch.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
   // this.getParentContractTypeName = this.getParentContractTypeName.bind(this);
  }
  
  componentDidMount(props) {
    console.log('componentDidMount')
    const {getBrand, addBrand, updateBrand, getCategories, getCountries, getContractTypes} = this.props;
    if(this.state.id) {
      getBrand(this.state.id);
    }
    getCategories({p:1});
    getCountries({p:2});
    getContractTypes({p:3});
  }
  
  componentDidUpdate(prevProps) {
    console.log('componentDidUpdate');
    if(!this.props.match.params.id) {
      if(!this.state.create) {
        //this.props.dispatch(reset('brandForm'));  // requires form name
        this.props.initialize();
        store.dispatch(resetBrand()); 
        this.setState({
          create: true,
          categories: null,
          countries: null,
          contractTypes: null
        });
      }
    }
    if(this.props.match.params.id) {
      if(this.state.categories==null && typeof(this.props.brand)!='undefined') {
        this.setState({categories: this.props.brand.categories.map(item=>item.id)});
        this.props.change('categories', this.props.brand.categories.map(category => ({ id: category.id, name: category.name.en }))); 
      }
      if(this.state.contractTypes==null && typeof(this.props.brand)!='undefined') {
        this.setState({contractTypes: this.props.brand.contract_types.map(item=>item.id)});
        this.props.change('contractTypes', this.props.brand.contract_types.map(contractType => ({ id: contractType.id, name: contractType.name.en+'ffff' }))); 
      }
      /*
      if(this.state.countries==null && typeof(this.props.brand)!='undefined') {
        this.setState({countries: this.props.brand.countries.map(item=>item.code)});
        this.props.change('countries', this.props.brand.countries.map(country => ({ code: country.code, name: country.name }))); 
      }
      */
    }
  }

  // Save Brand
  submit(values) {

    console.log('---submit');

    // Aliases
    if(typeof(this.props.brand)!='undefined') {
      var brandAliases = this.props.brand.aliases; // Old
      if(typeof(brandAliases)=='undefined') {
        brandAliases = [];
      }
      var aliases = values.aliases; // New
      var onlyInA = brandAliases.filter(comparer(aliases));
      var onlyInB = aliases.filter(comparer(brandAliases));
      var aliasesDiff = onlyInA.concat(onlyInB);
      for(var i=0; i<aliasesDiff.length; i++) {
        if(typeof(aliasesDiff[i].id)!='undefined') {
          aliases.push({id: aliasesDiff[i].id, _destroy: true});
        }
      }
      delete values.aliases;
      for(var i=0; i<aliases.length; i++) {
        var alias = [];
        values['aliases['+i+']'] = aliases[i];
      }
      //return;
    }

    var action;

    if(typeof(values.active)=='undefined') {
      values.active = false;
    }
    
    if(values.categories!=null && values.categories.length>0) {
      values.category_ids = values.categories.map(item=>item.id)
    }
    else {
      values.category_ids = [null];
    }

    if(values.contract_types!=null && values.contract_types.length>0) {
      values.contract_type_ids = values.contract_types.map(item=>item.id)
    }
    else {
      values.contract_type_ids = [null];
    }
    
    if(values.countries!=null && values.countries.length>0) {
      values.country_codes = values.countries.map(item=>item.code)
    }
    else {
      values.country_codes = [null];
    }
 
    //values.logo = this.state.base64;
    if((typeof(values.contact)!='undefined' && values.contact.email==null) || (typeof(values.contact)!='undefined' && values.contact.email=='')) {
      delete values.contact.email
    }

    if(this.state.create) {
      action = addBrand(values);
    }
    else {
      action = updateBrand(values);
    }
    store.dispatch(action).then((result) => {
      if(typeof(result)!='undefined') {
        this.setState({
          open: true, 
          clearChangesEnabled: false
        });
       // window.location.reload();
       // this.props.initialize();
       values.aliases = aliases;
       this.props.getBrand(this.state.id);
      }
  	}).catch((error) => {
  		throw new SubmissionError({_error:  error });
  	});	
  }
  
  handleClose() {
    this.setState({open: false});
  }

  handleClearChanges = () => {
    this.props.reset();
  };
   
  handleCountrySearch = debounce((searchTerms) => {
    this.countrySearch(searchTerms);
  }, 500);

  countrySearch(searchTerms) {
    console.log('handleCountrySearch');
    var params = {}
    var search = {}
    search['search[name]'] = encodeURIComponent(searchTerms.trim());
    Object.assign(params, search);
    store.dispatch(getCountries(params)).then((result) => {
      var countrySearchResult = result.payload.items.map(country => ({ code: country.code, name: country.name.en }));
      this.setState({countrySearchResult: countrySearchResult});
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    }); 
  }

  handleCategorySearch = debounce((searchTerms) => {
    this.categorySearch(searchTerms);
  }, 500);

  categorySearch(searchTerms) {
    console.log('handleCategorySearch');
    var params = {}
    var search = {}
    search['search[name]'] = encodeURIComponent(searchTerms.trim());
    Object.assign(params, search);
    store.dispatch(getCategories(params)).then((result) => {
      var categorySearchResult = result.payload.items.map(category => ({ id: category.id, name: category.name.en }));
      this.setState({categorySearchResult: categorySearchResult});
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    }); 
  }

  handleContractTypeSearch = debounce((searchTerms) => {
    this.contractTypeSearch(searchTerms);
  }, 500);

  contractTypeSearch(searchTerms) {
    var params = {}
    var search = {}
    search['search[name]'] = encodeURIComponent(searchTerms.trim());
    Object.assign(params, search);
    store.dispatch(getContractTypes(params)).then((result) => {
      var contractTypeSearchResult = result.payload.items.map(contractType => ({ id: contractType.id, name: contractType.name.en + this.getParentContractTypeName(contractType.parent_id), sub_types: typeof(contractType.sub_types)!='undefined' ? contractType.sub_types : []  }));
      this.setState({contractTypeSearchResult: contractTypeSearchResult});
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    }); 
  }

  getParentContractTypeName(id) {
    return '';
    if(id==null) {
      return '';
    }
    var contractTypes = this.props.contractTypes;
    if(contractTypes!=null) {
      var contractType = contractTypes.filter(item=>(item.id==id));
      if(typeof(contractType)!='undefined') {
        return ' (' + contractType.name.en + ')';
      }
      else {
        return 'g';
      }
    }
  }

  handleImageChange(e) {
    e.preventDefault();
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.setState({
        file: file,
        base64: reader.result
      });
    };
  }


  render() {
    
    const session = this.props.session
    if(typeof(session.forceLogout)!="undefined" && session.forceLogout==1) {
      this.setState({session: null});
      window.location.href="/login";
    }

    const { input, error, handleSubmit, pristine, reset, submitting, message, brands, categories, countries, contractTypes, brand } = this.props
    
    console.log('---render')
    
    var categoriesArr = categories.map(category => ({ id: category.id, name: category.name.en }));
    if(this.state.categorySearchResult!=null) {
      categoriesArr = this.state.categorySearchResult;
    }
    if(typeof(brand)!='undefined' && typeof(brand.categories)!='undefined' && brand.categories!=null) {
      var brandCategories = brand.categories;
      for(var i=0; i<brandCategories.length; i++) {
        var category = brandCategories[i];
        const found = categoriesArr.some(item => item.id === category.id);
        if(!found) {
          categoriesArr = categoriesArr.concat({id: category.id, name: category.name.en});
        }
      }
    }
    categoriesArr = categoriesArr.concat({id: 0, name: '...'});
    
    var countriesArr = countries.map(country => ({ code: country.code, name: country.name.en }));
    if(this.state.countrySearchResult!=null) {
      countriesArr = this.state.countrySearchResult;
    }
    if(typeof(brand)!='undefined' && typeof(brand.countries)!='undefined' && brand.countries!=null) {
      var brandCountries = brand.countries;
      for(var i=0; i<brandCountries.length; i++) {
        var country =  brandCountries[i];
        const found = countriesArr.some(item => item.id === country.id);
        if(!found) {
          countriesArr = countriesArr.concat({code: country.code, name: country.name.en});
        }
      }
    }
    countriesArr = countriesArr.concat({code: '', name: '...'});
    var contractTypesArr = contractTypes.filter(item=>(item.parent_id==null)).map(contractType => ({ id: contractType.id, name: contractType.name.en, sub_types: typeof(contractType.sub_types)!='undefined' ? contractType.sub_types : [] }));
    if(this.state.contractTypeSearchResult!=null) {
      contractTypesArr = this.state.contractTypeSearchResult.filter(item=>(item.parent_id==null));
    }
    var contractTypesArrLength = contractTypesArr.length;
    var contractTypesWithParents = [];
    for(var i=0; i< contractTypesArrLength; i++) {
      var parentContractType = contractTypesArr[i];
      contractTypesWithParents = contractTypesWithParents.concat({id: parentContractType.id , name: parentContractType.name});
      if(parentContractType.sub_types.length>0) {
        var subTypesArr = parentContractType.sub_types;
        for(var n=0; n<subTypesArr.length; n++) {
          var subType = subTypesArr[n];
          contractTypesWithParents = contractTypesWithParents.concat({id: subType.id , name: subType.name.en +' (' + parentContractType.name + ')'});
        }
      }
    }
    contractTypesArr = contractTypesWithParents;
    if(typeof(brand)!='undefined' && typeof(brand.contract_types)!='undefined' && brand.contract_types!=null) {
      var brandContractTypes = brand.contract_types;
      for(var i=0; i<brandContractTypes.length; i++) {
        var contractType = brandContractTypes[i];
        const found = contractTypesArr.some(item => item.id === contractType.id);
        if(!found) {
          contractTypesArr = contractTypesArr.concat({id: contractType.id, name: contractType.name.en, sub_types: typeof(contractType.sub_types)!='undefined' ? contractType.sub_types : []});
        }
      }
    }
    contractTypesArr = contractTypesArr.concat({id: 0, name: '...'});

    return (
      <div className="animated fadeIn mt-5">
        <form onSubmit={handleSubmit(this.submit)}>
          <Card>
            <CardHeader><i className="fa fa-align-justify"></i> Brand details</CardHeader>
            <CardBody>
              <CardColumns className="form-sections">
                <Card>
                  <CardBody>
                  <CardTitle className="d-inline">Brand</CardTitle>
                    {/*
                    <Row>  
                      <Col xs="12" lg="6">
                        <Field
                          name="identifier"
                          type="text"
                          component={renderField}
                          label="Identifier"
                          className="form-control"
                          asterisk="*"
                        />
                      </Col>
                    </Row>
                    */}
                   <Row className="row mt-3 mb-3">
                     <Col xs="12" lg="6">
                       <h5>Aliases</h5> 
                       <FieldArray  name="aliases" component={renderAliasMembers} />
                     </Col>
                   </Row>

                    <Row>
                      <Col xs="12" lg="6">
                        <Field
                          name="name"
                          type="text"
                          component={renderField}
                          label="Name"
                          className="form-control"
                          asterisk="*"
                        />
                      </Col>
                    </Row>
                    <Field
                      name="description.de"
                      type="textarea"
                      component={renderTextareaField}
                      label="Description DE"
                      className="form-control"
                    />
                    <Field
                      name="description.en"
                      type="textarea"
                      component={renderTextareaField}
                      label="Description EN"
                      className="form-control"
                    />
                    {/*
                    <br />
                    <div className="d-block d-sm-flex align-items-center">
                      <Label className="mr-3 pb-1">Brand type: </Label>
                      <Field name="online" component={renderCheckbox} color="primary" label="Online" />
                      <Field name="offline" component={renderCheckbox} color="primary" label="Offiline" />
                      <Field name="featured" component={renderCheckbox} color="primary" label="Featured" />
                    </div>
                    */}
                    <Row>  
                      <Col xs="12" lg="6">
                        <Field
                          name="rating"
                          type="number"
                          component={renderField}
                          label="Rating"
                          className="form-control"
                          disabled={true}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>  

                <FormSection name="address"><Address /></FormSection>

                <FormSection name="contact"><Contact /></FormSection>
                <Card>
                  <CardBody>
                    <CardTitle className="d-inline">Logo</CardTitle>
                    <Row className="mb-2">
                      <Col xs="12" lg="6">
                        <Label>Logo</Label><br />
                        <Field component={UploadFile} name='logo' accept='.jpg, .png' className="mb-3" />
                        {typeof(brand)!='undefined' && brand.logo!=null && !this.state.create &&
                          <img src={brand.logo} className="img-thumbnail brnd-logo" />
                        }
                      </Col> 
                      <Col xs="12" lg="6">
                        <Label>Logo 2x</Label><br />
                        <Field component={UploadFile} name='logo_2x' accept='.jpg, .png' className="mb-3" />
                         {typeof(brand)!='undefined' && brand.logo_2x!=null && !this.state.create &&
                          <img src={brand.logo_2x} className="img-thumbnail brnd-logo" />
                        }
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <CardTitle className="d-inline">Categories <span className="text-danger">*</span></CardTitle>
                    <Field
                      name="categories"
                      component={renderMultiselect}
                      data={categoriesArr}
                      value={[]}
                      valueField="id"
                      textField="name"
                      filter={false}
                      onSearch={this.handleCategorySearch}
                      messages={messages}
                      disabled={[{code:'', name: '...'}]}
                    />
                    <Label className="mt-1">Type to search Category e.g. Electronics</Label>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <CardTitle className="d-inline">Contract Types <span className="text-danger">*</span></CardTitle>
                    <Field
                      name="contract_types"
                      component={renderMultiselect}
                      data={contractTypesArr}
                      value={[]}
                      valueField="id"
                      textField="name"
                      filter="contains"
                      /*onSearch={this.handleContractTypeSearch}*/
                      messages={messages}
                      disabled={contractTypes.filter(item=>(item.parent_id==null)).map(ct=>({id:ct.id})).concat({id: 0})}
                    />
                    <Label className="mt-1">Type to search Contract Type e.g. Insurances</Label>
                  </CardBody>
                </Card>
                {/*
                <Card>
                  <CardBody>
                    <CardTitle className="d-inline">Targeted Countries</CardTitle>
                    <Field
                      name="countries"
                      component={renderMultiselect}
                      data={countriesArr}
                      value={[]}
                      valueField="code"
                      textField="name"
                      filter={false}
                      onSearch={this.handleCountrySearch}
                      messages={messages}
                      disabled={[{code:'', name: '...'}]}
                    />
                    <Label className="mt-1">Type to search Country e.g. Germany</Label>
                  </CardBody>
                </Card>
                */}
                {/*
                <Card>
                  <CardBody>
                    <CardTitle className="d-inline">Parameters</CardTitle>
                    <div className="d-flex mb-4">
                      <Field
                        name="vat"
                        type="number"
                        component={renderField}
                        label="VAT"
                        className="form-control"
                      />
                      <Field
                        name="tolerance"
                        type="number"
                        component={renderField}
                        label="Tolerance"
                        className="form-control"
                      />
                    </div>
                  </CardBody>
                </Card>    
                <Shipping />
                */}
                <Tag />
                <Card>
                  <CardBody>
                    <CardTitle className="d-inline">Others</CardTitle>
                    <Row>
                      <Col xs="12" lg="6">
                        <Field
                          name="website"
                          type="text"
                          component={renderField}
                          label="Website"
                          className="form-control"
                        />
                        <Field
                          name="twitter"
                          type="text"
                          component={renderField}
                          label="Twitter"
                          className="form-control"
                        />
                        <Field
                          name="facebook"
                          type="text"
                          component={renderField}
                          label="Facebook"
                          className="form-control"
                        />
                        <Field
                          name="snapchat"
                          type="text"
                          component={renderField}
                          label="Snapchat"
                          className="form-control"
                        />
                        <Field
                          name="instagram"
                          type="text"
                          component={renderField}
                          label="Instagram"
                          className="form-control"
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                
              </CardColumns>
              <Row>                   
                 <Col xs="12">
                  {/*<Field name="active" component={renderCheckbox} color="primary" label="Active" />*/}
                  {!!error && <div className="form-error mt-3">{error && <strong>{error}</strong>}</div>}
                  <button type="submit" className="btn btn-primary mt-3" disabled={pristine || submitting}>Submit</button>
                  {/*<button type="button" className="btn btn-light mt-5 ml-2" disabled={!this.state.clearChangesEnabled} onClick={this.handleClearChanges}>Clear changes</button>*/}
                </Col>
              </Row>          
            </CardBody>
          </Card>
        </form>
        <Snackbar open={this.state.open} autoHideDuration={2000} message="Saved" onClose={() => this.setState({open: false})}
          action={
            <React.Fragment>
              <a className="text-white cursor-pointer mr-2" aria-label="close" onClick={this.handleClose}><i className="cil-x"></i></a>
            </React.Fragment>
          } 
        />
      </div>
    )
  }
}

BrandForm.propTypes = {
  pristine: PropTypes.bool,
  message: PropTypes.string,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  brand: PropTypes.object,
  categories: PropTypes.array,
  countries: PropTypes.array,
  contractTypes: PropTypes.array,
  session: PropTypes.bool
};

function mapStateToProps(state) {
  console.log('STATE');
  console.log(state);
    return {
      //formData: state.brands,
      initialValues: typeof(state.brandForm)!=='undefined' ? state.brandForm.brand : null,
      brand: typeof(state.brandForm)!=='undefined' ? state.brandForm.brand : null,
      categories: (typeof(state.categories)!=='undefined' && typeof(state.categories.result)!=='undefined') ? state.categories.result.items : [],
      countries: (typeof(state.countries)!=='undefined' && typeof(state.countries.result)!=='undefined') ? state.countries.result.items : [],
      contractTypes: (typeof(state.contractTypes)!=='undefined' && typeof(state.contractTypes.result)!=='undefined') ? state.contractTypes.result.items : [],
      session: state.session
    }
  };

const mapDispatchToProps = dispatch => bindActionCreators({change, getBrand, addBrand, updateBrand, resetBrand, getCategories, getCountries, getContractTypes, checkAuth}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({
   form: 'brandForm', // a unique identifier for this form,
   enableReinitialize: true,
   validate
})(BrandForm))