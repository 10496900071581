import $ from 'jquery';
export const API_URL = 'https://stageapi.benfido.com';
export const ANALYTIC_API_URL = 'https://bi.benfido.com/api';
export const X_API_VERSION = 'v1';
export const X_API_KEY = '34287DB798AD7645F04E6607F2996A';


const zoom = {
      // Container for pan options
      pan: {
        // Boolean to enable panning
        enabled: false,

        // Panning directions. Remove the appropriate direction to disable
        // Eg. 'y' would only allow panning in the y direction
        // A function that is called as the user is panning and returns the
        // available directions can also be used:
        //   mode: function({ chart }) {
        //     return 'xy';
        //   },
        mode: 'x',

        rangeMin: {
          // Format of min pan range depends on scale type
          x: null,
          y: null
        },
        rangeMax: {
          // Format of max pan range depends on scale type
          x: null,
          y: null
        },

        // On category scale, factor of pan velocity
        speed: 20,

        // Minimal pan distance required before actually applying pan
        threshold: 10,

        // Function called while the user is panning
        onPan: function({chart}) { console.log(`I'm panning!!!`); },
        // Function called once panning is completed
        onPanComplete: function({chart}) { console.log(`I was panned!!!`); }
      },

      // Container for zoom options
      zoom: {
        // Boolean to enable zooming
        enabled: true,

        // Enable drag-to-zoom behavior
        drag: true,

        // Drag-to-zoom effect can be customized
        // drag: {
        //   borderColor: 'rgba(225,225,225,0.3)'
        //   borderWidth: 5,
        //   backgroundColor: 'rgb(225,225,225)',
        //   animationDuration: 0
        // },

        // Zooming directions. Remove the appropriate direction to disable
        // Eg. 'y' would only allow zooming in the y direction
        // A function that is called as the user is zooming and returns the
        // available directions can also be used:
        //   mode: function({ chart }) {
        //     return 'xy';
        //   },
        mode: 'x',

        rangeMin: {
          // Format of min zoom range depends on scale type
          x: null,
          y: null
        },
        rangeMax: {
          // Format of max zoom range depends on scale type
          x: null,
          y: null
        },

        // Speed of zoom via mouse wheel
        // (percentage of zoom on a wheel event)
        speed: 0.1,

        // Minimal zoom distance required before actually applying zoom
        threshold: 2,

        // On category scale, minimal zoom level before actually applying zoom
        sensitivity: 3,

        // Function called while the user is zooming
        onZoom: function({chart}) { console.log(`I'm zooming!!!`); },
        // Function called once zooming is completed
        onZoomComplete: function({chart}) { 
          console.log(`I was zoomed!!!`); 
          var ticks = chart.scales['x-axis-0'].ticks.length; //x-axis ticks array
          var labels = chart.data.labels.length; //labels array
          if (true || ticks < labels) {
            //reset_zoom.hidden = false;}
            var id = chart.canvas.id;
            $('#'+id).closest('div').find('.reset-zoom').show();
          }
          else {
            //reset_zoom.hidden = true;
            console.log('Hide reset button');
          }
        }
      }
    }

export const optionsDark = {
  responsive: true,
   maintainAspectRatio: false,
  animation: {
    duration: 0
  },
  layout: {
    padding: {
      top: 10
    }
  },
  legend: {
    display: true,
    labels: {
      // This more specific font property overrides the global property
      fontColor: '#90979d'
    }
  },
  scales: {
    xAxes: [{
      type: 'time',
      time: {
        unit: 'day',
        fillGapsWithZero: true,
        parser: 'YYYY-MM-DD',
        minUnit: 'day'
      },
      barPercentage: 0.4,
      gridLines: {color: '#404147'},
      ticks: {fontColor: '#939AA1'}
    }],
    yAxes: [{
      barPercentage: 0.4,
      gridLines: {color: '#404147'},
      ticks: {maxTicksLimit: 3, fontColor: '#939AA1'}
    }],
  },
  plugins: {
    zoom: zoom,
    labels: {
      render: 'value',
      fontColor: '#447D9B',
    }
  }
}
export const optionsLight = {
  responsive: true,
   maintainAspectRatio: false,
  animation: {
    duration: 0
  },
  layout: {
    padding: {
      top: 10
    }
  },
  legend: {
    display: true,
    labels: {
      // This more specific font property overrides the global property
      fontColor: 'black'
    }
  },
  scales: {
    xAxes: [{
      type: 'time',
      time: {
        unit: 'day'
      },
      barPercentage: 0.4,
      gridLines: {color: 'rgba(0,0,0,0.05)'},
      ticks: {fontColor: 'black'}
    }],
    yAxes: [{
      barPercentage: 0.4,
      gridLines: {color: 'rgba(0,0,0,0.05)'},
      ticks: {maxTicksLimit: 3, fontColor: 'black'}
    }],
  },
  plugins: {
    zoom: zoom,
    labels: {
      render: 'value',
      fontColor: '#F3A261',
    }
  }
}

export const lineDatasetProps = {
  fontColor: 'red',
  //backgroundColor: 'rgba(0,0,0,0.5)',
  //backgroundColor: colors,
  //borderColor: '#447D9B',
  borderWidth: 1,
  lineTension: 0,
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  //pointBorderColor: '#447D9B',
  pointBackgroundColor: 'rgba(255,255,255,1.5)',
  pointBorderWidth: 2,
  pointHoverRadius: 4,
  pointHoverBackgroundColor: 'rgba(75,192,192,1)',
  pointHoverBorderColor: 'rgba(220,220,220,1)',
  pointHoverBorderWidth: 2,
  pointRadius: 3,
  pointHitRadius: 10  
};

export const doughnutOptions = {
  animation: {
    duration: 0
  },
  layout: {
    padding: {
      top: 10
    }
  },
  legend: {
    display: true,
    position: 'left',
    labels: {
      // This more specific font property overrides the global property
      fontColor: '#90979d'
    }
  },
  scales: {
    xAxes: [{
      display: false,
      barPercentage: 0.4,
      gridLines: {color: '#404147'},
      ticks: {fontColor: '#939AA1'}
    }],
    yAxes: [{
      display: false,
      barPercentage: 0.4,
      gridLines: {color: '#404147'},
      ticks: {fontColor: '#939AA1'}
    }],
  },
  cutoutPercentage: 60,
  plugins: {
    labels: {
      render: 'value',
      fontColor: '#000000',
    }
  }
}  

export const barOptionsLight = {
    responsive: true,
    scales: {
      xAxes: [{
        //type: 'time',
        //time: {
        //  unit: 'day'
        //},
        barPercentage: 1,
        categoryPercentage: 0.4,
        gridLines: {color: 'rgba(0,0,0,0.05)'},
        ticks: {fontColor: 'black'}
      }],
      yAxes: [{
        barPercentage: 0.4,
        gridLines: {color: 'rgba(0,0,0,0.05)'},
        ticks: {maxTicksLimit: 3, fontColor: 'black'}
      }],
    },
    plugins: {
      labels: {
        render: 'value',
        fontColor: '#000000',
      }
    }
  }

export const barOptionsDark = {
    responsive: true,
    legend: {
    display: true,
    labels: {
      // This more specific font property overrides the global property
      fontColor: '#90979d'
    }
  },
  scales: {
    xAxes: [{
        //type: 'time',
        //time: {
        //  unit: 'day'
        //},
        barPercentage: 1,
        categoryPercentage: 0.4,
        gridLines: {color: '#404147'},
        ticks: {fontColor: '#939AA1'}
      }],
      yAxes: [{
        barPercentage: 0.4,
        gridLines: {color: '#404147'},
        ticks: {maxTicksLimit: 3, fontColor: '#939AA1'}
      }],
    },
    plugins: {
      labels: {
        render: 'value',
        fontColor: '#ffffff',
      }
    }
  }
