import React, {Component, useState} from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';


export default function CategoryShow(props) {

	const [activeTab, setActiveTab] = useState('1');
 	const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

	let {category} = props;
	return (
		<div>
			<Nav tabs>
      <NavItem>
        <NavLink
          className={classnames({ active: activeTab === '1' })}
          onClick={() => { toggle('1'); }} 
        > Category
        </NavLink>
      </NavItem>
    </Nav>
    <TabContent activeTab={activeTab}>
      <TabPane tabId="1">
        <Row>
          <Col sm="12">
            <table className="table mt-4">
							<tbody>
				        <tr>
				          <th className="bg-light w-50">Active:</th><td>{category.active ? 'Yes' : 'No'}</td>
				        </tr>
				        <tr>
				          <th className="bg-light">Name:</th><td>{category.name}</td>
				        </tr>
			        </tbody>
			      </table>
          </Col>
        </Row>
      </TabPane>
    </TabContent>
		</div>
	)

}
