// Action Types
import {GET_COUNTRY_SUCCESS, GET_COUNTRYS_SUCCESS, ADD_COUNTRY_SUCCESS, ADD_COUNTRY_ERROR, UPDATE_COUNTRY_ERROR} from '../actions/actionTypes';

// Utils
import { getNewState } from '../shared/utils/frontend';

// Initial State
const initialState = {};

export default function countryFormReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COUNTRY_SUCCESS: {
      const {payload: country} = action;
      return getNewState(state, {country});
      break;
    }
    case ADD_COUNTRY_SUCCESS: {
      const {payload: country} = action;
      return getNewState(state, {country});
      break;
    }
    case ADD_COUNTRY_ERROR: {
      const {payload: country} = action;
      return getNewState(state, {country});
      break;  
    }    
    case UPDATE_COUNTRY_ERROR: {
      const {payload: country} = action;
      return getNewState(state, {country});
      break;
    }
    default:
      return state;
  }
}