export const validate = values => {

  const errors = {}
  if (!values.brand || String(values.brand).trim()=='' || typeof(values.brand)=='undefined') {
    errors.brand = 'Required';
  }
  if (!values.title) {
    errors.title = 'Required';
  } else if (values.title.length > 35) {
    errors.title = 'Must be 35 characters or less'
  }
  if (!values.code) {
    errors.code = 'Required';
  }
  if (!values.description) {
    errors.description = 'Required'
  }
  if (!values.amount) {
    errors.amount = 'Required'
  } else if (isNaN(Number(values.amount))) {
    errors.amount = 'Must be a number'
  }
  if (!values.unit) {
    errors.unit = 'Required';
  }
  /*
  if (!values.turnover) {
    errors.turnover = 'Required'
  } else if (isNaN(Number(values.turnover))) {
    errors.turnover = 'Must be a number'
  }
  */

  return errors
}