import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import * as router from 'react-router-dom';
import { Container } from 'reactstrap';
import { element } from 'prop-types';
import {setCookie, getCookie} from '../shared/utils/cookies';

import './App.css';
// Layout Components
import Sidebar from '../shared/components/layout/Sidebar';
import Header from '../shared/components/layout/Header';
import Content from '../shared/components/layout/Content';
import Footer from '../shared/components/layout/Footer';

import {
  AppAside,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppBreadcrumb2 as AppBreadcrumb,
  AppSidebarNav2 as AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
//import navigation from './_nav';
import routes from '../routes';
import DefaultAside from '../shared/components/layout/DefaultAside';
import DefaultHeader from '../shared/components/layout/DefaultHeader';
import DefaultFooter from '../shared/components/layout/DefaultFooter';


import {UserContext, user} from '../shared/context/user';
import {ThemeContext, theme} from '../shared/context/theme';

import {refreshToken} from '../actions/sessionActions';
// Redux Store
import configureStore from '../shared/redux/configureStore';
// Configuring Redux Store
const store = configureStore(window.initialState);

const Banks = React.lazy(() => import('./bank/Banks'));
const BankForm = React.lazy(() => import('./bank/BankForm'));

const Dashboard = React.lazy(() => import('./dashboard/Dashboard'));

const Analytic = React.lazy(() => import('./analytic/Analytic'));

const Metrics = React.lazy(() => import('./analytic/Metrics'));
const Customer = React.lazy(() => import('./analytic/Customer'));
const Performance = React.lazy(() => import('./analytic/Performance'));

const Brands = React.lazy(() => import('./brand/Brands'));
const BrandForm = React.lazy(() => import('./brand/BrandForm'));

const Categories = React.lazy(() => import('./category/Categories'));
const CategoryForm = React.lazy(() => import('./category/CategoryForm'));

const Countries = React.lazy(() => import('./country/Countries'));
const CountryForm = React.lazy(() => import('./country/CountryForm'));

const Coupons = React.lazy(() => import('./coupon/Coupons'));
const CouponForm = React.lazy(() => import('./coupon/CouponForm'));

const Cashbacks = React.lazy(() => import('./cashback/Cashbacks'));
const CashbackForm = React.lazy(() => import('./cashback/CashbackForm'));

const Payments = React.lazy(() => import('./payment/Payments'));
const PaymentForm = React.lazy(() => import('./payment/PaymentForm'));

const Customers = React.lazy(() => import('./customer/Customers'));
const CustomerForm = React.lazy(() => import('./customer/CustomerForm'));

const Transactions = React.lazy(() => import('./transaction/Transactions'));
const TransactionForm = React.lazy(() => import('./transaction/TransactionForm'));

const Reviews = React.lazy(() => import('./review/Reviews'));
const ReviewForm = React.lazy(() => import('./review/ReviewForm'));

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

const routesArr = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/cms', exact: true, name: 'CMS' },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  // Banks
  { path: '/banks/new', name: 'New Bank', component: BankForm },
  { path: '/banks/edit/:id', name: 'Edit Bank', component: BankForm },
  { path: '/banks', name: 'Banks', component: Banks, exact: true },
  { path: '/banks/banks', name: 'Banks list', component: Banks },
  // Brands
  { path: '/brands/new', name: 'New Brand', component: BrandForm },
  { path: '/brands/edit/:id', name: 'Edit Brand', component: BrandForm },
  { path: '/brands', name: 'Brands', component: Brands, exact: true },
  { path: '/brands/brands', name: 'Brands list', component: Brands },
  // Categories
  { path: '/categories/new', name: 'New Category', component: CategoryForm },
  { path: '/categories/edit/:id', name: 'Edit Category', component: CategoryForm },
  { path: '/categories', name: 'Categories', component: Categories, exact: true },
  { path: '/categories/categories', name: 'Categories list', component: Categories },
  // Countries
  { path: '/countries/new', name: 'New Country', component: CountryForm },
  { path: '/countries/edit/:id', name: 'Edit Country', component: CountryForm },
  { path: '/countries', name: 'Countries', component: Countries, exact: true },
  { path: '/countries/countries', name: 'Countries list', component: Countries },
  // Coupons
  { path: '/coupons/new', name: 'New Coupon', component: CouponForm },
  { path: '/coupons/edit/:id', name: 'Edit Coupon', component: CouponForm },
  { path: '/coupons', name: 'Coupons', component: Coupons, exact: true },
  { path: '/coupons/coupons', name: 'Coupons list', component: Coupons },
  // Cashbacks
  { path: '/cashbacks/new', name: 'New Cashback', component: CashbackForm },
  { path: '/cashbacks/edit/:id', name: 'Edit Cashback', component: CashbackForm },
  { path: '/cashbacks', name: 'Cashbacks', component: Cashbacks, exact: true },
  { path: '/cashbacks/cashbacks', name: 'Cashbacks list', component: Cashbacks },
  // Payments
  { path: '/payments/new', name: 'New Payment', component: PaymentForm },
  { path: '/payments/edit/:id/:bank_id', name: 'Edit Payment', component: PaymentForm },
  { path: '/payments', name: 'Payments', component: Payments, exact: true },
  { path: '/payments/payments', name: 'Payments list', component: Payments },
  // Customers
  { path: '/customers/new/:bank_id', name: 'New Customer', component: CustomerForm },
  { path: '/customers/edit/:id/:bank_id', name: 'Edit Customer', component: CustomerForm },
  { path: '/customers', name: 'Customers', component: Customers, exact: true },
  { path: '/customers/customers', name: 'Customers list', component: Customers },
  // Transactions
  { path: '/transactions/new', name: 'New Transaction', component: TransactionForm },
  { path: '/transactions/edit/:id/:bank_id', name: 'Edit Transaction', component: TransactionForm },
  { path: '/transactions', name: 'Transactions', component: Transactions, exact: true },
  { path: '/transactions/transactions', name: 'Transactions list', component: Transactions },
  // Reviews
  { path: '/reviews/new', name: 'New Review', component: ReviewForm },
  { path: '/reviews/edit/:id/:bank_id', name: 'Edit Review', component: ReviewForm },
  { path: '/reviews', name: 'Reviews', component: Reviews, exact: true },
  { path: '/reviews/reviews', name: 'Reviews list', component: Reviews },
  // Analytic
  { path: '/analytic/customer', name: 'Customer', component: Analytic },
  { path: '/analytic/performance', name: 'Performance', component: Analytic },
  { path: '/analytic/metrics', name: 'Metrics', component: Analytic },

];

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      theme: localStorage.getItem('theme')==null ? 'dark' : localStorage.getItem('theme'),
      seconds: getCookie('_strexp')*1000
    };
    document.body.classList.add(localStorage.getItem('theme')==null ? 'dark-mode' : localStorage.getItem('theme')+'-mode');
    this.switchDarkMode = this.switchDarkMode.bind(this);
  }
  componentDidMount() {

    console.log('---App componentDidMount');
    var now = new Date();
    var expDate = getCookie('_strexp');
    var secondsRemain = (expDate-(now/1000));
    this.setState({seconds: expDate*1000});

    setInterval(()=>{
      if(secondsRemain>0) {
        console.log('Refresh!');
        store.dispatch(refreshToken()).then((result)=>{
          now = new Date();
          now.setMinutes(now.getMinutes()+60)
          this.setState({seconds: now.getTime()});
        }).then((error)=>{
          console.log(error);
        });
      }
      else {
        console.log('Expired!');
      }
    }, 3*60*1000);

  }

  componentDidUpdate(prevProps, prevState) {
    console.log('---App componentDidUpdate');
  }

  switchDarkMode() {
    if(this.state.theme=='light') {
      this.setState({theme: 'dark'});
      document.body.classList.remove('light-mode');
      document.body.classList.add('dark-mode');
      localStorage.setItem('theme', 'dark');
    }
    else {
      this.setState({theme: 'light'});
      document.body.classList.remove('dark-mode');
      document.body.classList.add('light-mode');
      localStorage.setItem('theme', 'light');
    }
  }

  render() {
    return (
      <div className="app">
        <ThemeContext.Provider value={{state: this.state, theme: theme, seconds: this.state.seconds}}>
          <UserContext.Provider value={user}>
            <AppHeader fixed>
              <DefaultHeader switchDarkMode={this.switchDarkMode} />
            </AppHeader>  
            <div className="app-body">
              <AppSidebar fixed display="lg">
                <AppSidebarHeader />
                <AppSidebarForm />
                <Sidebar {...this.props.children}/>
                <AppSidebarFooter />
                <AppSidebarMinimizer />
              </AppSidebar>
              <main className="main">
                <AppBreadcrumb appRoutes={routesArr} router={router} />
                <Container fluid>
                  <Content>{this.props.children}</Content>
                </Container>
              </main>
              <AppAside fixed>
                <DefaultAside />
              </AppAside>
            </div>
            <AppFooter>
              <DefaultFooter />
            </AppFooter>
          </UserContext.Provider>
        </ThemeContext.Provider>
      </div>
    )
  }
}

App.propsTypes = {
  children: element
}

export default App;