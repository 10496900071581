import React, {Component} from 'react';
import { Field, FieldArray } from 'redux-form'
import { Form, Input, Button, Card, CardTitle, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { renderField, renderCheckbox  } from '../form-field/ReduxFormFields';

const renderSubFields = (member, index, fields) => (
  <div key={index} className="d-flex align-items-end">
    <Field
      name={`${member}.id`}
      type="text"
      component={renderField}
      label={null}
      className="form-control d-none"
      disabled={true}
    />
    <Field
      name={`${member}.name`}
      type="text"
      component={renderField}
      label="Name"
      className="form-control"
    />
    <div className="mb-2">
      <button
        type="button"
        title="Remove BrandAlias"
        onClick={() => { if(window.confirm('Are you sure you want to remove?')) { 
          fields.remove(index); 
        }}}
        className="btn btn-light btn-sm mb-1"
      >
        <i className="cil-x"></i>
      </button>
    </div>
  </div>
)
const renderMembers = ({ fields }) => (
  <div className="d-flex flex-column mr-0 aliases-column">
    {fields.map(renderSubFields)}
    <div>
      <a type="button" className="btn-link d-inline-block mb-3 ml-2" onClick={() => fields.push({})}>
        <i className="cil-plus"></i> Add Alias
      </a>
    </div>
  </div>
)  
class BrandAlias extends Component {

  render() {

    return (
      <FieldArray name="aliases" component={renderMembers} />
    )
  }
}

export default BrandAlias;