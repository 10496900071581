// Dependencies

// Action Types
import {
  SET_START_DATE,
  SET_END_DATE,
  SET_BANK_ID
} from './actionTypes';

const setStartDate = (startDate) => ({
  type: SET_START_DATE, payload: startDate
});
const setEndDate = (endDate) => ({
  type: SET_END_DATE, payload: endDate
});
const setBankId = (bankId) => ({
  type: SET_BANK_ID, payload: bankId
});
export { setStartDate, setEndDate, setBankId };
