import React, {Component} from 'react';
import { PropTypes, array, bool } from 'prop-types';
import { Field, FormSection, reduxForm } from 'redux-form'
import Address from '../../shared/components/form-section/Address';
import Contact from '../../shared/components/form-section/Contact';

import { Form, Input, Button, Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import Snackbar from '@material-ui/core/Snackbar';

import SimpleLineIcon from 'react-simple-line-icons';
import { renderField, renderTextareaField, renderSelectField, renderCheckbox } from '../../shared/components/form-field/ReduxFormFields';

import { reset } from 'redux-form';
import { SubmissionError } from 'redux-form'
import { stopSubmit } from 'redux-form'

import { getBank, addBank, updateBank, resetBank } from '../../actions/bankActions'; 
import { checkAuth } from '../../actions/sessionActions';
import { validate } from './validate';

import './BankForm.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Redux Store
import configureStore from '../../shared/redux/configureStore';
// Configuring Redux Store
const store = configureStore(window.initialState);

class BankForm extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      open: false,
      create: false
    }
    this.submit = this.submit.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  
  componentDidMount(props) {
    console.log('componentDidMount');
    console.log(props);
    const {getBank, addBank, updateBank} = this.props;
    if(this.state.id) {
      getBank(this.state.id);    
    }
  }
  
  componentDidUpdate(prevProps) {
    console.log('componentDidUpdate')
    console.log(this.props);
    if(!this.props.match.params.id) {
      if(!this.state.create) {
        //this.props.dispatch(reset('bankForm'));  // requires form name
        this.props.initialize();
        store.dispatch(resetBank());
        this.setState({create: true});
      }
    }
  }

  // Save Bank
  submit(values) {
    var action;
    if(typeof(values.active)=='undefined') {
      values.active = false;
    }
    if(this.state.create) {
      action = addBank(values);
    }
    else {
      action = updateBank(values);
    }
    store.dispatch(action).then((result) => {
      if(typeof(result)!='undefined') {
        this.setState({open: true});
      }
  	}).catch((error) => {
  		throw new SubmissionError({_error:  error });
  	});	
  }
  
  handleClose() {
    this.setState({open: false});
  }
   
  render() {
    
    const session = this.props.session
    if(typeof(session.forceLogout)!="undefined" && session.forceLogout==1) {
      this.setState({session: null});
      window.location.href="/login";
    }
           
    const { error, handleSubmit, pristine, reset, submitting, message } = this.props
    
    return (
      <div className="animated fadeIn mt-5">
        <form onSubmit={handleSubmit(this.submit)}>
          <Row>
            <Col xs="12" lg="12">
              <Card>
                <CardHeader><i className="fa fa-align-justify"></i> Bank</CardHeader>
                <CardBody>
                  <Row className="form-sections">
                    <Col xs="12" lg="6">
                      <FormSection className="form-section">
                        <Card>
                          <CardBody>
                            <CardTitle>Company</CardTitle>
                            <Field
                              name="name"
                              type="text"
                              component={renderField}
                              label="Name"
                              className="form-control"
                              asterisk="*"
                            />
                            <Field
                              name="identifier"
                              type="text"
                              component={renderField}
                              label="Identifier"
                              className="form-control"
                              asterisk="*"
                            />
                            <Field
                              name="api_key"
                              type="text"
                              component={renderField}
                              label="API Key"
                              className="form-control"
                              disabled={true}
                            />
                            <Field
                              name="url"
                              type="text"
                              component={renderField}
                              label="URL"
                              className="form-control"
                            />
                            <br />
                            <Field name="active" component={renderCheckbox} color="primary" label="Active" />
                          </CardBody>
                        </Card>
                      </FormSection>
                    </Col>
                  </Row>
                  <Row className="mt-3 form-sections">                
                    <Col xs="12" lg="6"> 
                      <FormSection name="address"><Address /></FormSection>
                    </Col>
                    <Col xs="12" lg="6">
                      <FormSection name="contact"><Contact /></FormSection>
                    </Col>
                  </Row>
                  <Row>                   
                     <Col xs="12">
                      {!!error && <div className="form-error mt-3">{error && <strong>{error}</strong>}</div>}
                      <button type="submit" className="btn btn-primary mt-5" disabled={pristine || submitting}>Submit</button>
                    </Col>
                  </Row>          
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
        <Snackbar open={this.state.open} autoHideDuration={1000} message="Saved" 
          action={
            <React.Fragment>
              <a className="text-white cursor-pointer mr-2" aria-label="close" onClick={this.handleClose}><i className="cil-x"></i></a>
            </React.Fragment>
          } 
        />
      </div>
    )
  }
}

BankForm.propTypes = {
  pristine: PropTypes.bool,
  message: PropTypes.string,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  bank: PropTypes.object,
  initialValues: PropTypes.object
};

function mapStateToProps(state) {
  console.log('STATE');
  console.log(state);
    return {
      //formData: state.banks,
      initialValues: typeof(state.bankForm)!=='undefined' ? state.bankForm.bank : null,
      bank: typeof(state.bankForm)!=='undefined' ? state.bankForm.bank : null,
      session: state.session
    }
  };

const mapDispatchToProps = dispatch => bindActionCreators({getBank, addBank, updateBank, resetBank, checkAuth}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({
   form: 'bankForm', // a unique identifier for this form,
   enableReinitialize: true,
   validate
})(BankForm))