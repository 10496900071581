import React, {Component, useState, useEffect} from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody, CardHeader, CardTitle, CardText, Row, Col, Progress } from 'reactstrap';
import { Bar, Doughnut, Line, Pie, Polar, Radar } from 'react-chartjs-2';
import 'chartjs-plugin-labels';
//import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import classnames from 'classnames';
import Widget from '../../shared/components/widget/Widget';

import { getCustomerTransactions, getCustomerPayments } from '../../actions/customerActions';

// Redux Store
import configureStore from '../../shared/redux/configureStore';
// Configuring Redux Store
const store = configureStore(window.initialState);

export default function CustomerShow(props) {

	const [activeTab, setActiveTab] = useState('1');
  const [transactions, setTransactions] = useState([]);
  const [payments, setPayments] = useState([]);

  useEffect( ()=>{
    // Transactions
    var customerParams = { bank_id: props.bankId, 'search[customer]': props.customerId};
    store.dispatch(getCustomerTransactions(customerParams)).then((result) => {
      console.log('transacions items');
      console.log(result.payload.items);
      setTransactions(result.payload.items);
    }).catch((error) => {
      alert(error);
    });
  }, [props.bankId, props.customerId]);

  useEffect( ()=>{
    // Payments
    var customerParams = { bank_id: props.bankId, 'search[customer]': props.customerId};
    store.dispatch(getCustomerPayments(customerParams)).then((result) => {
      console.log('payments items');
      console.log(result.payload.items);
      setPayments(result.payload.items);
    }).catch((error) => {
      alert(error);
    });
  }, [props.bankId, props.customerId]);


 	const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  const renderPayments = payment =>  {
    return ( 
      <tr>
        <td className="w-50"><strong>{typeof(payment.name.en)!='undefined' ? payment.name.en : payment.name }</strong></td>
        <td className="w-25 text-right"><strong>{payment.percentage}%</strong></td>
        <td className="w-25 text-right"><strong>{payment.amount} €</strong></td>
      </tr>
    )
  }

  function pieData(payments) {
    let labels = [];
    let data = [];
    payments.map(function(payment) {
      labels.push(typeof(payment.name.en)!='undefined' ? payment.name.en : payment.name);
      data.push(payment.percentage);
    })
    var colors = ['#0f358e','#1d3557','#457b9d','#a8dadc','#f1faee','#f91f43','#e76f51','#f4a261','#e9c46a','#f4e3b8', '#2A9D8F'];
    const chartData  =  {
      labels: labels,
      datasets: [
        {
          data: data,
          backgroundColor: colors,
          hoverBackgroundColor: colors
      }]
    }
    return chartData;
  }

  const options = {
    plugins: {
      labels: {
        // render 'label', 'value', 'percentage', 'image' or custom function, default is 'percentage'
        render:  function (args) {
          // args will be something like:
          // { label: 'Label', value: 123, percentage: 50, index: 0, dataset: {...} }
          return '';
          //return args.value +'%';
  
          // return object if it is image
          // return { src: 'image.png', width: 16, height: 16 };
        },

        // precision for percentage, default is 0
        precision: 0,

        // identifies whether or not labels of value 0 are displayed, default is false
        showZero: true,

        // font size, default is defaultFontSize
        fontSize: 12,

        // font color, can be color array for each data or function for dynamic color, default is defaultFontColor
        fontColor: '#fff',

        // show the real calculated percentages from the values and don't apply the additional logic to fit the percentages to 100 in total, default is false
        showActualPercentages: false,
      }
    },
    legend: {
      "position": "left",
      "labels": {
        boxWidth: 10
      }
    },
    cutoutPercentage: 60,
  }

	let {customer} = props;
  console.log(props);
	return (
		<div className="mt-2">
			<Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); }}
          >
          Customer
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
          >
          Balance
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => { toggle('3'); }}
          >
          Shopping profile
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '4' })}
            onClick={() => { toggle('4'); }}
          >
          Transactions ({transactions.length})
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '5' })}
            onClick={() => { toggle('5'); }}
          >
          Payments ({payments.length})
          </NavLink>
        </NavItem>

      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12" lg="8">
              <table className="table table-striped mt-4">
  							<tbody>
  				        <tr>
                    <th>Name:</th>
                    <th>Gender:</th>
                    <th>Locale:</th>
                    <th>Country code:</th>
                    <th>Active:</th>
                  </tr>
                  <tr> 
                    <td>{customer.name && customer.name!='' ? customer.name : '---'}</td>
                    <td>{customer.gender && customer.gender!='' ? customer.gender : '---'}</td>
                    <td>{customer.locale}</td>
                    <td>{customer.country_code}</td>
                    <td>{customer.active ? 'Yes' : 'No'}</td>
                  </tr>
  			        </tbody>
  			      </table>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col sm="12">
              <div className="d-flex mt-4">
                <Widget 
                  icon="cil-credit-card" 
                  header={customer.profile.total_earnings} 
                  children="Total earnings" 
                  progressColor="success" 
                  progressValue={40} 
                  classes="w-25" 
                />
                <Widget 
                  icon="cil-balance-scale" 
                  header={customer.profile.current_balance} 
                  children="Current balance" 
                  progressColor="info" 
                  progressValue={40} 
                  classes="w-25 ml-2" 
                />
                <Widget 
                  icon="cil-clock" 
                  header={customer.profile.pending_balance} 
                  children="Pending balance" 
                  progressColor="warning" 
                  progressValue={40} 
                  classes="w-25 ml-2" 
                />
                <Widget 
                  icon="cil-check-circle" 
                  header={customer.profile.payments_total} 
                  children="Payments total" 
                  progressColor="primary" 
                  progressValue={40} 
                  classes="w-25 ml-2" 
                />
              </div>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row className="mt-4">
            <Col sm="12" lg="4">
              <Card className="payments-card">
                <CardHeader><CardTitle className="mb-0"><strong>Spendings by Medium</strong></CardTitle></CardHeader>
                <CardBody>
                  <table className="table table-sm mb-0">
                    <tbody>
                      { customer.profile.payments_by_medium.map(payment => (
                          renderPayments(payment)
                        ))
                      }
                    </tbody>
                  </table>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <div className="chart-wrapper">
                    <Pie data={pieData(customer.profile.payments_by_medium)} options={options} />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="12" lg="4">
              <Card className="payments-card">
                <CardHeader><CardTitle className="mb-0"><strong>Top Category Spendings</strong></CardTitle></CardHeader>
                <CardBody>
                  <table className="table table-sm mb-0">
                    <tbody>
                      { customer.profile.payments_by_category.map(payment => (
                          renderPayments(payment)
                        ))
                      }
                    </tbody>
                  </table>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <div className="chart-wrapper">
                    <Pie data={pieData(customer.profile.payments_by_category)} options={options} />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="12" lg="4">
              <Card className="payments-card">
                <CardHeader><CardTitle className="mb-0"><strong>Top Brand Spendings</strong></CardTitle></CardHeader>
                <CardBody>
                  <table className="table table-sm mb-0">
                    <tbody>
                      { customer.profile.payments_by_brand.map(payment => (
                          renderPayments(payment)
                        ))
                      }
                    </tbody>
                  </table>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <div className="chart-wrapper">
                    <Pie data={pieData(customer.profile.payments_by_brand)} options={options} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="4">
          <table className="table mt-3 mb-5">
          <tr>
            <th>Reference</th>
            <th>Brand</th>
            <th>Type</th>
            <th>Booked at</th>
            <th>Amount</th>
            <th>Status</th>
          </tr>
          {transactions.map((transaction)=>{
            return (
              <tr>
                <td>{transaction.reference}</td>
                <td>{transaction.brand!=null ? transaction.brand.name : '---'}</td>
                <td>{transaction.type}</td>
                <td>{transaction.booked_at!=null ? transaction.booked_at.substring(0, 10) : ''}</td>
                <td>{transaction.amount}</td>
                <td>{transaction.status}</td>
              </tr>
              )
          })}
          </table>
        </TabPane>
        <TabPane tabId="5">
          <table className="table mt-3 mb-5">
          <tr>
            <th>Reference</th>
            <th>Description</th>
            <th>Brand</th>
            <th>Booked at</th>
            <th>Amount</th>
          </tr>
          {payments.map((payment)=>{
            return (
              <tr>
                <td>{payment.reference}</td>
                <td>{payment.description}</td>
                <td>{payment.brand!=null ? payment.brand.name : '---'}</td>
                <td>{payment.booked_at!=null ? payment.booked_at.substring(0, 10) : ''}</td>
                <td>{payment.amount}</td>
              </tr>
              )
          })}
          </table>
        </TabPane>
      </TabContent>
		</div>
	)

}
