import React, {Component} from 'react';
import { PropTypes, array, bool } from 'prop-types';
import { Field, FormSection, reduxForm } from 'redux-form'

import { Form, Input, Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import Snackbar from '@material-ui/core/Snackbar';

import SimpleLineIcon from 'react-simple-line-icons';
import { renderField, renderTextareaField, renderSelectField, renderCheckbox } from '../../shared/components/form-field/ReduxFormFields';

import { reset } from 'redux-form';
import { SubmissionError } from 'redux-form'
import { stopSubmit } from 'redux-form'

import { getCustomer, addCustomer, updateCustomer, resetCustomer } from '../../actions/customerActions'; 
import { getBrands } from '../../actions/brandActions';
import { checkAuth } from '../../actions/sessionActions';
import { validate } from './validate';

import './CustomerForm.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Redux Store
import configureStore from '../../shared/redux/configureStore';
// Configuring Redux Store
const store = configureStore(window.initialState);

class CustomerForm extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      bankId: this.props.match.params.bank_id,
      open: false,
      create: false,
      clearChangesEnabled: true
    }
    this.submit = this.submit.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  
  componentDidMount(props) {
    console.log('componentDidMount');
    console.log(props);
    const {getCustomer, addCustomer, updateCustomer} = this.props;
    if(this.state.id) {
      getCustomer({id: this.state.id, bank_id: this.state.bankId});    
    }
    /* JWT is stored in memory */
    /*
    const {session} = this.props
    if(typeof(session.jwt)=='undefined') {
      sessionStorage.removeItem('jwt');
      this.setState({session: null});
      this.props.history.push({pathname: '/login'});
      window.location.href="/login";
    }
    */
  }
  
  componentDidUpdate(preVrops) {
    console.log('componentDidUpdate')
    console.log(this.props);
    if(!this.props.match.params.id) {
      if(!this.state.create) {
        //this.props.dispatch(reset('customerForm'));  // requires form name
        this.props.initialize();
        store.dispatch(resetCustomer());
        this.setState({create: true});
      }
    }
  }

  // Save Customer
  submit(values) {
    var action;
    if(typeof(values.active)=='undefined') {
      values.active = false;
    }
    if(this.state.create) {
      action = addCustomer(values, this.state.bankId);
    }
    else {
      action = updateCustomer(this.state.id, values, this.state.bankId);
    }
    store.dispatch(action).then((result) => {
      if(typeof(result)!='undefined') {
        this.setState({open: true});
        this.setState({clearChangesEnabled: false});
      }
  	}).catch((error) => {
  		throw new SubmissionError({_error:  error });
  	});	
  }
  
  handleClose() {
    this.setState({open: false});
  }

  handleClearChanges = () => {
    this.props.reset();
  };
   
  render() {
    
    const session = this.props.session
    if(typeof(session.forceLogout)!="undefined" && session.forceLogout==1) {
      this.setState({session: null});
      window.location.href="/login";
    }
           
    const { error, handleSubmit, pristine, reset, submitting, message } = this.props
    
    return (
      <div className="animated fadeIn mt-5">
        <form onSubmit={handleSubmit(this.submit)}>
          <Row>
            <Col xs="12" lg="12">
              <Card>
                <CardHeader><i className="fa fa-align-justify"></i> Customer details</CardHeader>
                <CardBody>
                  <Row>
                    <Col xs="12" lg="6">
                    <fieldset className="p-2 pb-5 pl-5 pr-5"><legend className="d-inline">Customer</legend>
                      <Row>
                        <Col xs="12" lg="6">
                          <Field
                            name="country_code"
                            type="text"
                            component={renderField}
                            label="Country code"
                            className="form-control"
                            asterisk="*"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" lg="6">
                          <Field
                            name="birth_year"
                            type="number"
                            component={renderField}
                            label="Birth year"
                            className="form-control"
                          />
                        </Col>
                        <Col xs="12" lg="6">
                          <Field
                            name="gender"
                            type="text"
                            component={renderField}
                            label="Gender"
                            className="form-control"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" lg="6">
                          <Field
                            name="email"
                            type="email"
                            component={renderField}
                            label="Username"
                            className="form-control"
                          />
                        </Col>
                        <Col xs="12" lg="6">
                          <Field
                            name="password"
                            type="password"
                            component={renderField}
                            label="Password"
                            className="form-control"
                          />
                        </Col>
                      </Row>
                      <br />
                      <Field name="active" component={renderCheckbox} color="primary" label="Active" />
                    </fieldset>
                  </Col>
                  </Row>
                  <Row>                   
                     <Col xs="12">
                      {!!error && <div className="form-error mt-3">{error && <strong>{error}</strong>}</div>}
                      <button type="submit" className="btn btn-primary mt-5" disabled={pristine || submitting}>Submit</button>
                      {/*<button type="button" className="btn btn-light mt-5 ml-2" disabled={!this.state.clearChangesEnabled} onClick={this.handleClearChanges}>Clear changes</button>*/}
                    </Col>
                  </Row>          
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
        <Snackbar open={this.state.open} autoHideDuration={2000} message="Saved" onClose={() => this.setState({open: false})}
          action={
            <React.Fragment>
              <a className="text-white cursor-pointer mr-2" aria-label="close" onClick={this.handleClose}><i className="cil-x"></i></a>
            </React.Fragment>
          } 
        />
      </div>
    )
  }
}

CustomerForm.propTypes = {
  pristine: PropTypes.bool,
  message: PropTypes.string,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  customer: PropTypes.object,
  brands: PropTypes.array,
  session: PropTypes.bool
};

function mapStateToProps(state) {
  console.log('STATE');
  console.log(state);
    return {
      //formData: state.countries,
      initialValues: typeof(state.customerForm)!=='undefined' ? state.customerForm.customer : null,
      customer: typeof(state.customerForm)!=='undefined' ? state.customerForm.customer : null,
      brands: (typeof(state.brands)!=='undefined' && typeof(state.brands.result)!=='undefined') ? state.brands.result.items : [],
      session: state.session
    }
  };

const mapDispatchToProps = dispatch => bindActionCreators({getCustomer, addCustomer, updateCustomer, resetCustomer, getBrands, checkAuth}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({
   form: 'customerForm', // a unique identifier for this form,
   enableReinitialize: true,
   validate
})(CustomerForm))