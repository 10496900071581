// Action Types
import {
        GET_REVIEW_SUCCESS, GET_REVIEWS_SUCCESS, ADD_REVIEW_SUCCESS
        } from '../actions/actionTypes';

// Utils
import { getNewState } from '../shared/utils/frontend';

// Initial State
const initialState = {};

export default function reviewReducer(state = initialState, action) {
  switch (action.type) {
    
    /*
    case GET_REVIEW_SUCCESS:
    {
      const {payload: review} = action;
      console.log('ACTION');
      console.log(action);
      return getNewState(state, {
        review
      });
    }
    break;
    */
    
    case GET_REVIEWS_SUCCESS:
    {
      const {payload: result} = action;
      console.log('ACTION');
      console.log(action);
      return getNewState(state, {
        result
      });
    }
    break;
    
    /*
    case ADD_REVIEW_SUCCESS:
    {
      const {payload: reviews} = action;
      return getNewState(state, {
        reviews
      });
    }
    break;
    */
 
  default:
      return state;
}
};