import React, {Component, useState} from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';


export default function ContractTypeShow(props) {

	const [activeTab, setActiveTab] = useState('1');
 	const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

	let {contractType} = props;
	return (
		<div>
			<Nav tabs>
      <NavItem>
        <NavLink
          className={classnames({ active: activeTab === '1' })}
          onClick={() => { toggle('1'); }}
        >
          ContractType
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={classnames({ active: activeTab === '2' })}
          onClick={() => { toggle('2'); }}
        >
          Contact
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={classnames({ active: activeTab === '3' })}
          onClick={() => { toggle('3'); }}
        >
          Address
        </NavLink>
      </NavItem>
    </Nav>
    <TabContent activeTab={activeTab}>
      <TabPane tabId="1">
        <Row>
          <Col sm="12">
            <table className="table mt-4">
				   <tbody>
				        <tr>
				          <th className="bg-light w-50">Active:</th><td>{contractType.active ? 'Yes' : 'No'}</td>
				        </tr>
				        <tr>
				          <th className="bg-light">Name:</th><td>{contractType.name.en}</td>
				        </tr>
				        <tr>
				          <th className="bg-light">Booking name:</th><td>{contractType.booking_name}</td>
				        </tr>
				        <tr>
				          <th className="bg-light">Website:</th><td>{contractType.website ? contractType.website : '---' }</td>
				        </tr>
				        <tr>
				          <th className="bg-light">Description EN:</th><td>{contractType.description.en ? contractType.description.en : '---' }</td>
				        </tr>
				        {/*
				        <tr>
				          <th className="bg-light">Online:</th><td>{contractType.online ? 'Yes' : 'No'}</td>
				        </tr>
				        <tr>
				          <th className="bg-light">Offline:</th><td>{contractType.offline ? 'Yes' : 'No'}</td>
				        </tr>
				        <tr>
				          <th className="bg-light">Featured:</th><td>{contractType.featured ? 'Yes' : 'No'}</td>
				        </tr>
				    	*/}
				        <tr>
				          <th className="bg-light">Instagram:</th><td>{contractType.instagram ? contractType.instagram : '---'}</td>
				        </tr>
				        <tr>
				          <th className="bg-light">Facebook:</th><td>{contractType.facebook ? contractType.facebook : '---'}</td>
				        </tr>
				        <tr>
				          <th className="bg-light">Twitter:</th><td>{contractType.twitter ? contractType.twitter : '---'}</td>
				        </tr>
				        <tr>
				          <th className="bg-light">Snapchat:</th><td>{contractType.snapchat ? contractType.snapchat : '---'}</td>
				        </tr>
			        </tbody>
			      </table>
          </Col>
        </Row>
      </TabPane>
      <TabPane tabId="2">
      	<Row>
          <Col sm="12">
          	{ contractType.contact!=null &&
              <table className="table mt-4">
  							<tbody>
  							 <tr>
  				          <th className="bg-light w-50">Position:</th><td>{contractType.contact.position}</td>
  				        </tr>
  				        <tr>
  				          <th className="bg-light">Salutation:</th><td>{contractType.contact.salutation}</td>
  				        </tr>
  				        <tr>
  				          <th className="bg-light">First name:</th><td>{contractType.contact.first_name}</td>
  				        </tr>
  				        <tr>
  				          <th className="bg-light">Last name:</th><td>{contractType.contact.last_name}</td>
  				        </tr>
  				        <tr>
  				          <th className="bg-light">Email:</th><td>{contractType.contact.email}</td>
  				        </tr>
  				        <tr>
  				          <th className="bg-light">Phone:</th><td>{contractType.contact.phone}</td>
  				        </tr>
  				        <tr>
  				          <th className="bg-light">Fax:</th><td>{contractType.contact.fax}</td>
  				        </tr>
  				        </tbody>
  				    </table>
            }
          </Col>
        </Row>
      </TabPane>
      <TabPane tabId="3">
				<Row>
          <Col sm="12">
            { contractType.address!=null &&
              <table className="table mt-4">
  							<tbody>
  				        <tr>
  				          <th className="bg-light w-50">Title:</th><td>{contractType.address.title}</td>
  				        </tr>
  				        <tr>
  				          <th className="bg-light">Country:</th><td>{contractType.address.country}</td>
  				        </tr>
  				        <tr>
  				          <th className="bg-light">City:</th><td>{contractType.address.city}</td>
  				        </tr>
  				        <tr>
  				          <th className="bg-light">Street:</th><td>{contractType.address.street}</td>
  				        </tr>
  				        <tr>
  				          <th className="bg-light">ZIP:</th><td>{contractType.address.zip}</td>
  				        </tr>
  				        </tbody>
  				    </table>
            }
          </Col>
        </Row>
      </TabPane>
    </TabContent>
		</div>
	)

}
