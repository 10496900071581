// Dependencies
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// Components
import App from './components/App';
import AppLogin from './components/AppLogin';
import Home from './components/home/Home';
import Dashboard from './components/dashboard/Dashboard';

import LogInPage from './components/login/LogInPage';

import Banks from './components/bank/Banks';
import BankForm from './components/bank/BankForm';

import Brands from './components/brand/Brands';
import BrandForm from './components/brand/BrandForm';

import Categories from './components/category/Categories';
import CategoryForm from './components/category/CategoryForm';

import Countries from './components/country/Countries';
import CountryForm from './components/country/CountryForm';

import Coupons from './components/coupon/Coupons';
import CouponForm from './components/coupon/CouponForm';

import Cashbacks from './components/cashback/Cashbacks';
import CashbackForm from './components/cashback/CashbackForm';

import Payments from './components/payment/Payments';
import PaymentForm from './components/payment/PaymentForm';

import Customers from './components/customer/Customers';
import CustomerForm from './components/customer/CustomerForm';

import Transactions from './components/transaction/Transactions';
import TransactionForm from './components/transaction/TransactionForm';

import Reviews from './components/review/Reviews';
import ReviewForm from './components/review/ReviewForm';

import ContractTypes from './components/contract-type/ContractTypes';
import ContractTypeForm from './components/contract-type/ContractTypeForm';

import Customer from './components/analytic/Customer';
import Performance from './components/analytic/Performance';
import Metrics from './components/analytic/Metrics';
import Analytic from './components/analytic/Analytic';

import Page404 from './components/page/Page404';

import {setCookie, getCookie} from './shared/utils/cookies';
import {checkAccountDetails} from './shared/utils/account';

function loginCheck() {
   checkAccountDetails();
   return getCookie('_ud') && getCookie('_str')!=='' && getCookie('_str')!=='undefined';
}

const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route {...rest} render={(props) => (
      loginCheck()
      ? <Layout><Component {...props} /></Layout>
      : <Redirect to='/login' exact />
  )} />
);

const AppRoutes = () => (
      <Switch>
        <Route path="/login" component={LogInPage}  layout={AppLogin} />
        <PrivateRoute path="/" component={Dashboard} exact layout={App} />
        <PrivateRoute path="/dashboard" component={Dashboard} exact layout={App} />
        

        <PrivateRoute path="/banks/edit/:id" name="Edit Bank" exact={false} component={BankForm} layout={App}/>
        <PrivateRoute path="/banks/new/" name="New Bank" exact={false} component={BankForm} layout={App}/>
        <PrivateRoute path="/banks" name="Banks" component={Banks} exact layout={App}/>
        <PrivateRoute path="/banks/banks" name="Banks" component={Banks} exact={false} layout={App}/>

        <PrivateRoute path="/brands/edit/:id" name="Edit Brand" exact={false} component={BrandForm} layout={App}/>
        <PrivateRoute path="/brands/new/" name="New Brand" exact={false} component={BrandForm} layout={App}/>
        <PrivateRoute path="/brands" name="Brands" component={Brands} exact layout={App}/>
        <PrivateRoute path="/brands/brands" name="Brands" component={Brands} exact={false} layout={App}/>

        <PrivateRoute path="/categories/edit/:id" name="Edit Category" exact={false} component={CategoryForm} layout={App}/>
        <PrivateRoute path="/categories/new/" name="New Category" exact={false} component={CategoryForm} layout={App}/>
        <PrivateRoute path="/categories" name="Categories" component={Categories} exact layout={App}/>
        <PrivateRoute path="/categories/categories" name="Categories" component={Categories} exact={false} layout={App}/>

        <PrivateRoute path="/countries/edit/:code" name="Edit Country" exact={false} component={CountryForm} layout={App}/>
        <PrivateRoute path="/countries/new/" name="New Country" exact={false} component={CountryForm} layout={App}/>
        <PrivateRoute path="/countries" name="Countries" component={Countries} exact layout={App}/>
        <PrivateRoute path="/countries/countries" name="Countries" component={Countries} exact={false} layout={App}/>

        <PrivateRoute path="/coupons/edit/:id" name="Edit Coupon" exact={false} component={CouponForm} layout={App}/>
        <PrivateRoute path="/coupons/new/" name="New Coupon" exact={false} component={CouponForm} layout={App}/>
        <PrivateRoute path="/coupons" name="Coupons" component={Coupons} exact layout={App}/>
        <PrivateRoute path="/coupons/coupons" name="Coupons" component={Coupons} exact={false} layout={App}/>

        <PrivateRoute path="/cashbacks/edit/:id" name="Edit Cashback" exact={false} component={CashbackForm} layout={App}/>
        <PrivateRoute path="/cashbacks/new/" name="New Cashback" exact={false} component={CashbackForm} layout={App}/>
        <PrivateRoute path="/cashbacks" name="Cashbacks" component={Cashbacks} exact layout={App}/>
        <PrivateRoute path="/cashbacks/cashbacks" name="Cashbacks" component={Cashbacks} exact={false} layout={App}/>

        <PrivateRoute path="/payments/edit/:id/:bank_id" name="Edit Payment" exact={false} component={PaymentForm} layout={App}/>
        <PrivateRoute path="/payments/new/" name="New Payment" exact={false} component={PaymentForm} layout={App}/>
        <PrivateRoute path="/payments" name="Payments" component={Payments} exact layout={App}/>
        <PrivateRoute path="/payments/payments" name="Payments" component={Payments} exact={false} layout={App}/>

        <PrivateRoute path="/customers/edit/:id/:bank_id" name="Edit Customer" exact={false} component={CustomerForm} layout={App}/>
        <PrivateRoute path="/customers/new/:bank_id" name="New Customer" exact={false} component={CustomerForm} layout={App}/>
        <PrivateRoute path="/customers" name="Customers" component={Customers} exact layout={App}/>
        <PrivateRoute path="/customers/customers" name="Customers" component={Customers} exact={false} layout={App}/>

        <PrivateRoute path="/transactions/edit/:id/:bank_id" name="Edit Transaction" exact={false} component={TransactionForm} layout={App}/>
        <PrivateRoute path="/transactions/new/" name="New Transaction" exact={false} component={TransactionForm} layout={App}/>
        <PrivateRoute path="/transactions" name="Transactions" component={Transactions} exact layout={App}/>
        <PrivateRoute path="/transactions/transactions" name="Transactions" component={Transactions} exact={false} layout={App}/>

        <PrivateRoute path="/reviews/edit/:id/:bank_id" name="Edit Review" exact={false} component={ReviewForm} layout={App}/>
        <PrivateRoute path="/reviews/new/" name="New Review" exact={false} component={ReviewForm} layout={App}/>
        <PrivateRoute path="/reviews" name="Reviews" component={Reviews} exact layout={App}/>
        <PrivateRoute path="/reviews/reviews" name="Reviews" component={Reviews} exact={false} layout={App}/>

        <PrivateRoute path="/analytic" name="Analytic" component={Analytic} exact layout={App}/>
        <PrivateRoute path="/analytic/customer" name="Customer" component={Analytic} exact={false} layout={App}/>
        <PrivateRoute path="/analytic/performance" name="Performance" component={Analytic} exact={false} layout={App}/>
        <PrivateRoute path="/analytic/metrics" name="Metrics" component={Analytic} exact={false} layout={App}/>

        <PrivateRoute path="/contract-types/edit/:id" name="Edit ContractType" exact={false} component={ContractTypeForm} layout={App}/>
        <PrivateRoute path="/contract-types/new/" name="New ContractType" exact={false} component={ContractTypeForm} layout={App}/>
        <PrivateRoute path="/contract-types" name="ContractTypes" component={ContractTypes} exact layout={App}/>
        <PrivateRoute path="/contract-types/contract-types" name="ContractTypes" component={ContractTypes} exact={false} layout={App}/>



        <Route component={Page404} />
      </Switch>
);

export default AppRoutes;