// Action Types
import {GET_BANK_SUCCESS, GET_BANKS_SUCCESS, ADD_BANK_SUCCESS, ADD_BANK_ERROR, UPDATE_BANK_ERROR} from '../actions/actionTypes';

// Utils
import { getNewState } from '../shared/utils/frontend';

// Initial State
const initialState = {};

export default function bankFormReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BANK_SUCCESS: {
      const {payload: bank} = action;
      return getNewState(state, {bank});
      break;
    }
    case ADD_BANK_SUCCESS: {
      const {payload: bank} = action;
      return getNewState(state, {bank});
      break;
    }
    case ADD_BANK_ERROR: {
      const {payload: bank} = action;
      return getNewState(state, {bank});
      break;
    }
    case UPDATE_BANK_ERROR: {
      const {payload: bank} = action;
      return getNewState(state, {bank});
      break;
    }
    default:
      return state;
  }
}
