// Action Types
import {GET_COUPON_SUCCESS, GET_COUPONS_SUCCESS, ADD_COUPON_SUCCESS, ADD_COUPON_ERROR, UPDATE_COUPON_ERROR} from '../actions/actionTypes';

// Utils
import { getNewState } from '../shared/utils/frontend';

// Initial State
const initialState = {};

export default function couponFormReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COUPON_SUCCESS: {
      const {payload: coupon} = action;
      return getNewState(state, {coupon});
      break;
    }
    case ADD_COUPON_SUCCESS: {
      const {payload: coupon} = action;
      return getNewState(state, {coupon});
      break;
    }
    case ADD_COUPON_ERROR: {
      const {payload: coupon} = action;
      return getNewState(state, {coupon});
      break;
    }
    case UPDATE_COUPON_ERROR: {
      const {payload: coupon} = action;
      return getNewState(state, {coupon});
      break;
    }
    default:
      return state;
  }
}
