// Dependencies
import axios from 'axios';
import { API_URL, X_API_KEY, X_API_VERSION } from '../conf.js';
import { getCookie } from '../shared/utils/cookies';
import { axiosInterceptors } from '../shared/utils/axiosInterceptors';

// Action Types
import {
  GET_CATEGORY_REQUEST,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_ERROR,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_ERROR,
  ADD_CATEGORY_REQUEST,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_ERROR,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_ERROR
} from './actionTypes';

// Base Actions
import { request, received, error } from '../shared/redux/baseActions';

const getCategory = (params) => dispatch => {
  dispatch(request(GET_CATEGORY_REQUEST));
  var id = '';
  if (typeof (params) !== 'undefined') {
    id = params;
  }
  // Axios Data
  const axiosData = {
    method: 'GET',
    url: API_URL + '/categories/' + id,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'X-Api-Version' : X_API_VERSION,
      'Authorization': 'Bearer ' + getCookie("_str")
    }
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(GET_CATEGORY_SUCCESS, response.data)))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(GET_CATEGORY_ERROR));
    });
};

const getCategories = (params) => dispatch => {
  dispatch(request(GET_CATEGORIES_REQUEST));
  var perPage = 10;
  var page = 1;
  console.log('GET_CATEGORIES');
  console.log(params);
  var url = API_URL + '/categories?';
  if (typeof (params) != 'undefined') {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    console.log(queryString);
    url += queryString;
  }
  // Axios Data
  const axiosData = {
    method: 'GET',
    url: url,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'X-Api-Version' : X_API_VERSION,
      'Authorization': 'Bearer ' + getCookie("_str")
    }
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(GET_CATEGORIES_SUCCESS, { items: response.data['items'], meta: response.data["meta"] })))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(GET_CATEGORIES_ERROR));
    });
};

const addCategory = (params) => dispatch => {
  dispatch(request(ADD_CATEGORY_REQUEST));
  var id = '';
  if (typeof (params) !== 'undefined') {
    id = params;
  }
  console.log('ADD_CATEGORY');
  console.log(params);
  params.address_attributes = params.address
  params.contact_attributes = params.contact
  // Axios Data
  const axiosData = {
    method: 'POST',
    url: API_URL + '/categories',
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'X-Api-Version' : X_API_VERSION,
      'Authorization': 'Bearer ' + getCookie("_str")
    },
    data: params
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(ADD_CATEGORY_SUCCESS, response.data['form'])))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(ADD_CATEGORY_ERROR, err.response));
    });
};

const updateCategory = (params) => dispatch => {
  dispatch(request(UPDATE_CATEGORY_REQUEST));
  var id = '';
  if (typeof (params) !== 'undefined') {
    id = params;
  }
  console.log('UPDATE_CATEGORY');
  console.log(params);
  params = { ...params, ...{ address_attributes: params.address } }
  params = { ...params, ...{ contact_attributes: params.contact } }
  // Axios Data
  const axiosData = {
    method: 'PATCH',
    url: API_URL + '/categories/' + params.id,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'X-Api-Version' : X_API_VERSION,
      'Authorization': 'Bearer ' + getCookie("_str")
    },
    data: params
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(UPDATE_CATEGORY_SUCCESS, response.data['form'])))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(UPDATE_CATEGORY_ERROR, err.response));
    });
};

const resetCategory = () => dispatch => {
  dispatch(received(ADD_CATEGORY_SUCCESS, []))
};

export { getCategory, getCategories, addCategory, updateCategory, resetCategory };
