export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer'
    },
    {
      name: 'Brands',
      url: '/brands',
      icon: 'icon-exclamation',
      attributes: {},
      children: [
        {
          name: '- Brands list',
          url: '/brands/brands',
        },
        {
          name: '- Add Brand',
          url: '/brands/new',
        }
      ]
    },
    {
      name: 'Offers',
      url: '/offers',
      icon: 'icon-plus',
      children: [
       {
          name: '- Coupons CLO',
          url: '/coupons/coupons',
          attributes: {}
        },
        {
          name: '- Cashbacks CLC',
          url: '/cashbacks/cashbacks',
          attributes: {}
        }
      ],
    },
    {
      name: 'Banks',
      url: '/banks',
      icon: 'icon-organization',
      attributes: {},
      children: [
        {
          name: '- Banks list',
          url: '/banks/banks',
        },
        {
          name: '- Add Bank',
          url: '/banks/new',
        }
      ]
    },
    {
      name: 'Customers',
      url: '/customers',
      icon: 'icon-user',
      attributes: {},
      children: [
        {
          name: '- Customers list',
          url: '/customers/customers',
        },
        {
          name: '- Add Customer',
          url: '/customers/new',
        }
      ]
    },
    {
      name: 'Payments',
      url: '/payments/payments',
      icon: 'icon-layers',
      attributes: {}
    },
    {
      name: 'Transactions',
      url: '/transactions/transactions',
      icon: 'icon-credit-card',
      attributes: {}
    },
    {
      name: 'Contract Types',
      url: '/contract-types',
      icon: 'icon-note',
      attributes: {},
      children: [
        {
          name: '- Contract Types list',
          url: '/contract-types/contract-types',
        },
        {
          name: '- Add Contract Type',
          url: '/contract-types/new',
        }
      ]
    },
    {
      name: 'Reviews',
      url: '/reviews/reviews',
      icon: 'icon-pencil',
      attributes: {}
    },
    {
      name: 'Analytics',
      url: '/not-found',
      icon: 'icon-pie-chart',
      attributes: { disabled: false },
      children: [
        {
          name: '- Customer',
          url: '/analytic/customer',
          attributes: {},
        },
        {
          name: '- Performance',
          url: '/analytic/performance',
          attributes: {},
        },
        {
          name: '- Metrics',
          url: '/analytic/metrics',
          attributes: {},
        }
      ]
    },
    {
      name: 'CMS',
      url: 'https://crft.benfido.com/admin',
      icon: 'icon-doc',
      attributes: { target: '_blank', className: "external" },
    },
    {
      name: 'Settings',
      url: '/not-found',
      icon: 'icon-settings',
      attributes: { disabled: true },
      children: [
        {
          name: '- Countries',
          url: '/countries',
          attributes: {},
        },
        {
          name: '- Categories',
          url: '/categories',
          attributes: {}
        },
        {
          name: '- Languages',
          url: '#',
          attributes: { disabled: true },
        },
        {
          name: '- Admin users',
          url: '#',
          attributes: { disabled: true },
        },
      ]
    }
  ],
};
