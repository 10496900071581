import React, {Component} from 'react';
import { PropTypes, array, bool } from 'prop-types';
import { Field, FormSection, reduxForm } from 'redux-form'
import Address from '../../shared/components/form-section/Address';
import Contact from '../../shared/components/form-section/Contact';

import { Form, Input, Button, Card, CardColumns, CardTitle, CardBody, CardHeader, Col, Row } from 'reactstrap';
import Snackbar from '@material-ui/core/Snackbar';

import SimpleLineIcon from 'react-simple-line-icons';
import { renderField, renderTextareaField, renderSelectField, renderCheckbox, renderDateField, renderRadioGroup } from '../../shared/components/form-field/ReduxFormFields';

import { Radio, RadioGroup } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import { reset } from 'redux-form';
import { SubmissionError } from 'redux-form'
import { stopSubmit } from 'redux-form'

import { getTransaction, addTransaction, updateTransaction, resetTransaction } from '../../actions/transactionActions'; 
import { getBrands } from '../../actions/brandActions';
import { checkAuth } from '../../actions/sessionActions';
import { validate } from './validate';

import './TransactionForm.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Redux Store
import configureStore from '../../shared/redux/configureStore';
// Configuring Redux Store
const store = configureStore(window.initialState);

class TransactionForm extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      open: false,
      create: false,
      brands: null,
      selectedBrand: '',
      status: null
    }
    this.submit = this.submit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
  }
  
  componentDidMount(props) {
    console.log('componentDidMount');
    console.log(props);
    const {getTransaction, addTransaction, updateTransaction, getBrands } = this.props;
    if(this.state.id) {
      getTransaction({id: this.state.id, bank_id: 1});    
    }
    getBrands();
   
  }
  
  componentDidUpdate(prevProps) {
    console.log('componentDidUpdate')
    console.log(this.props);
    if(!this.props.match.params.id) {
      if(!this.state.create) {
        //this.props.dispatch(reset('transactionForm'));  // requires form name
        this.props.initialize();
        store.dispatch(resetTransaction());
        this.setState({create: true});
        this.setState({brands: null});
      }
    }
  }

  // Save Transaction
  submit(values) {

    var action;

    if(this.state.brands!=null) {
      values.brand_ids = values.brands.map(item=>item.id)
    }
    else {
      values.brand_ids = [];
    }

    values.brand_id = values.brand;

    if(this.state.create) {
      action = addTransaction(values);
    }
    else {
      action = updateTransaction(values, this.props.match.params.bank_id);
    }
    store.dispatch(action).then((result) => {
      if(typeof(result)!='undefined') {
        this.setState({open: true});
      }
  	}).catch((error) => {
  		throw new SubmissionError({_error:  error });
  	});	
  }
  
  handleClose() {
    this.setState({open: false});
  }

  handleStatusChange(e) {
    this.setState({status: e=='' ? null : e});
  }
   
  render() {
    
    const session = this.props.session
    if(typeof(session.forceLogout)!="undefined" && session.forceLogout==1) {
      this.setState({session: null});
      window.location.href="/login";
    }
           
    const { error, handleSubmit, pristine, reset, submitting, message, brands, transaction } = this.props
    var status = '';
    if(transaction!=null && this.state.status==null) {
      status = transaction.status;
    }
    else {
      status = this.state.status;
    }
   
    return (
      <div className="animated fadeIn mt-5">
        <form onSubmit={handleSubmit(this.submit)}>
          <Row>
            <Col xs="12" lg="12">
              <Card>
                <CardHeader><i className="fa fa-align-justify"></i> Transaction</CardHeader>
                <CardBody>
                  <CardColumns className="form-sections">
                    <Card>
                      <CardBody>
                        <CardTitle className="d-inline">Transaction details</CardTitle>
                          <Field name="status" className="mt-3" component={renderRadioGroup} defaultValue={status} onChange={this.handleStatusChange}>
                            <FormControlLabel value="pending" control={<Radio color="primary" />} label="Pending" />
                            <FormControlLabel value="confirmed" control={<Radio color="primary" />} label="Confirmed" />
                            <FormControlLabel value="declined" control={<Radio color="primary" />} label="Declined" />
                          </Field>
                      </CardBody>
                    </Card>
                  </CardColumns>
                  <Row>                   
                     <Col xs="12">
                      {!!error && <div className="form-error mt-3">{error && <strong>{error}</strong>}</div>}
                      <button type="submit" className="btn btn-primary mt-5" disabled={pristine || submitting}>Submit</button>
                    </Col>
                  </Row>          
                </CardBody>
              </Card>
            </Col>
          </Row>
        </form>
        <Snackbar open={this.state.open} autoHideDuration={2000} message="Saved" onClose={() => this.setState({open: false})}
          action={
            <React.Fragment>
              <a className="text-white cursor-pointer mr-2" aria-label="close" onClick={this.handleClose}><i className="cil-x"></i></a>
            </React.Fragment>
          } 
        />
      </div>
    )
  }
}

TransactionForm.propTypes = {
  pristine: PropTypes.bool,
  message: PropTypes.string,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  transaction: PropTypes.object,
  brands: PropTypes.array,
  session: PropTypes.bool
};

function mapStateToProps(state) {
  console.log('STATE');
  console.log(state);
    return {
      //formData: state.transactions,
      initialValues: typeof(state.transactionForm)!=='undefined' ? state.transactionForm.transaction : null,
      transaction: typeof(state.transactionForm)!=='undefined' ? state.transactionForm.transaction : null,
      brands: (typeof(state.brands)!=='undefined' && typeof(state.brands.result)!=='undefined') ? state.brands.result.items : [],
      session: state.session
    }
  };

const mapDispatchToProps = dispatch => bindActionCreators({getTransaction, addTransaction, updateTransaction, resetTransaction, getBrands, checkAuth}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({
   form: 'transactionForm', // a unique identifier for this form,
   enableReinitialize: true,
   validate
})(TransactionForm))