import React from 'react';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import 'chartjs-plugin-draggable';
import 'hammerjs';
import 'chartjs-plugin-zoom';

class ZoomedLine extends React.Component {

  constructor(props) {
    super(props);
    this[props.id+"Reference"] = React.createRef();
    this.resetZoom = this.resetZoom.bind(this);
    this.state = {
      'type': 'line',
      chartRef: props.id+"Reference"
    }
  }

  resetZoom(ref) {
    let chart = this.refs[ref].chartInstance;
    chart.resetZoom();
    var id = chart.canvas.id;
    var button = document.querySelector('#'+id+'Container .reset-zoom');
    button.style.display='none';
    //var ticks = chart.chart.scales["x-axis-0"]["ticks"];
    //chart.chart.options.scales.xAxes[0].ticks.min = ticks[3];
    //chart.chart.options.scales.xAxes[0].ticks.max = ticks[4];
    //chart.update();
  }

  componentDidUpdate() {
    
    try {

      if(typeof(this.state.chartRef)!='undefined') {
        let chartInstance = this.refs[this.state.chartRef].chartInstance;
        if(typeof(chartInstance.config.data.datasets[0])!='undefined') {
          chartInstance.config.data.datasets[0].borderWidth = this.state.type=='donut' ? 4 : 0;
          //chartInstance.config.data.datasets[0].borderColor = this.context.state.theme=='dark' ? '#3B3D47' : '#ffffff';
          if(typeof(chartInstance.config.options.scales.xAxes[0].time)!='undefined') {
            //chartInstance.chart.config.options.scales.xAxes[0].time.unit = 'month';
            //chartInstance.chart.config.options.scales.xAxes[0].time.minUnit = 'month';
          }
          // fillGapsWithZero
          if(this.state.type=='line') {
            var c = chartInstance.chart;
            var timeAxis = c.options.scales.xAxes[0].time;
            if(typeof(timeAxis)!='undefined') {
              if (!timeAxis || !timeAxis.fillGapsWithZero) return;
              for (var i=0;i<c.data.datasets.length;i++){
                var set = c.data.datasets[i];
                if (!set.data.length) continue;
                var min, max, hash = {};
                for (var j=0;j<set.data.length;j++){
                  var val = moment(set.data[j].t, timeAxis.parser);
                  if (!min || min.diff(val)>0)
                    min = val;
                  if (!max || max.diff(val)<0)
                    max = val;
                  hash[set.data[j].t] = 1;
                }
                for (var val = min; max.diff(val)>0; val.add(1, timeAxis.minUnit)){
                  var d = val.format(timeAxis.parser);
                  if (!hash[d])
                    set.data.push({t:d, y:0});
                }
                set.data.sort(function(a,b){
                  return a.t < b.t?-1:1;
                });
              }
            }
          }
          chartInstance.canvas.parentNode.style.height = '228px';
          chartInstance.update();
          //window[redrawChart] = true;
        }
      }

    }
    catch(e) {
      console.log(e);
    }

  }

  render() {
    const {data, options, width, height, id, ref, redraw } = this.props;
    return (      
      <div id={id+"Container"} className="line-chart-container">
        <span className="btn btn-light btn-sm float-right reset-zoom" style={{display: 'none'}} onClick={()=>{this.resetZoom(id+"Reference")}}><span data-feather="zoom-out"></span>Reset</span>
        <Line 
          id={id}
          data={data} 
          options={options}
          ref={id+"Reference"} 
          redraw={redraw}
          width={width}
          height={height} 
        />
      </div>
    )
  }

}

export default ZoomedLine;