import React, {Component} from 'react';
import debounce from 'lodash/debounce';
import { PropTypes, array, bool } from 'prop-types';
import Combobox from 'react-widgets/lib/Combobox';
import 'react-widgets/dist/css/react-widgets.css';
import { renderCombobox } from '../form-field/ReduxFormFields';
import { getBrand, getBrands } from '../../../actions/brandActions';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Redux Store
import configureStore from '../../redux/configureStore';
// Configuring Redux Store
const store = configureStore(window.initialState);

class BrandCombobox extends Component { 
	
	constructor(props) {
	  super(props);
	  this.state = {
      open: false,
      create: false,
      search: null,
      brands: null,
      selectedBrand: '',
      brandSearchResult: null
    }
	  this.handleBrandChange = this.handleBrandChange.bind(this);
    this.handleBrandSearch = this.handleBrandSearch.bind(this);
	}

	componentDidMount(props) {
    console.log('componentDidMount');
    console.log(props);
    const { getBrands } = this.props;
    getBrands();
  }

  componentDidUpdate(prevProps) {
    console.log('componentDidUpdate')
    console.log(this.props);
  }

  handleBrandChange(e) {
  	console.log('--searchable-brand-change');
  	console.log(e);
    this.setState({selectedBrand: e=='' ? null : e});
    if(typeof(e.id)=='undefined') {
      this.handleBrandSearch(e);
    }
    else {
    	const search = Object.assign({}, this.state.search);
	    search['search[brand]'] = encodeURIComponent(e.id);
	    this.setState({search});
	    this.props.onChange('brand', e.id);
    }
  }

  handleBrandSearch = debounce((searchTerms) => {
    this.brandSearch(searchTerms);
  }, 500);

  brandSearch(searchTerms) {
    console.log('handleBrandSearch -- searchable');
    this.props.onChange('brand', '');
    var params = {}
    var search = {}
    search['search[name]'] = encodeURIComponent(searchTerms.trim());
    Object.assign(params, search);
    store.dispatch(getBrands(params)).then((result) => {
      var brandSearchResult = result.payload.items.map(brand => ({ id: brand.id, name: brand.name }));
      this.setState({brandSearchResult: brandSearchResult});
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    }); 
  }

	render() {

		const { brands } = this.props;

		console.log('---render');

    var selectedBrand = '';
    if(this.state.selectedBrand==null) {
      selectedBrand = '';
    }
    else if(this.state.selectedBrand!='') {  
      selectedBrand = this.state.selectedBrand;
    }
		var brandsArr = brands.map(brand => ({ id: brand.id, name: brand.name }));
    if(this.state.brandSearchResult!=null) {
      brandsArr = this.state.brandSearchResult;
    }
    brandsArr = brandsArr.concat({id: 0, name: '...'});

		return (
			<Combobox
			  name="brand"
			  type="select"
			  label="Brand - type to search Brand e.g. Adidas"
			  data={brandsArr}
			  defaultValue={selectedBrand}
			  valueField="id"
			  textField="name"
			  filter={true}
			  className={`p-0 pr-2 ${typeof(this.props.className)!='undefined' ? this.props.className : ''}`}
			  onChange={(event) => this.handleBrandChange(event)}
			  disabled={[{id:0, name: '...'}]}
			  placeholder="All Brands"
			  asterisk="*"
			/>
		)
	}

}

function mapStateToProps(state) {
	console.log('STATE')
	console.log(state);
  return {
    brands: (typeof(state.brands)!=='undefined' && typeof(state.brands.result)!=='undefined') ? state.brands.result.items : [],
    session: state.session
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({getBrands}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandCombobox)
